import {isObject} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection} from 'redux-form';
import {Checkbox} from 'redux-form-material-ui';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Assignees from '../../../components/relations/Assignees';
import OfferSearch from './OfferSearch';
import DatePicker from '../../../components/Input/DatePicker';
import TextField from '../../../components/Input/TextField';
import {numberWithSpaces} from '../../../utils';
import SuggestInput from '../../../components/Input/SuggestInput';
import MaskedInput from '../../../components/Input/MaskedInput';
import CheckboxInput from '../../../components/Input/Checkbox';

class Form extends Component {
  state = {
    feedbackWait: false,
  };

  render() {
    const {
      array: {push, remove},
      assignees,
      change,
      offerTypes,
      requestActions,
      requestResults,
      activities,
      activitiesDetails,
      dealSources,
      id,
      offers,
      onAssigneeSelect,
      onAssigneeUnselect,
      onOfferSelect,
      onOfferUnselect,
      propertyAddresses,
      team,
      activityId,
      activityDetailsId,
    } = this.props;

    const {feedbackWait} = this.state;

    return (
      <div style={{marginTop: 24}}>
        <FormSection name="request">
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Field
                name="date"
                label="Дата заявки"
                format={null}
                component={props => (
                  <DatePicker format="yyyy-MM-dd" clearable {...props} />
                )}
                fullWidth
                margin="none"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="type"
                label="Вид сделки"
                required
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                {offerTypes.map(({value, label}, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="source"
                label="Источник"
                required
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                <MenuItem value={null} />
                {dealSources.map(({value, label}, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="contactPhone"
                label="Номер телефона"
                component={TextField}
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {mask: '+7 999 999-99-99'},
                }}
                fullWidth
                margin="none"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="contactName"
                label="Имя"
                component={TextField}
                fullWidth
                margin="none"
                required
              />
            </Grid>
            {!id ? (
              <Grid item xs={12}>
                <FormControl margin="none">
                  <FormControlLabel
                    control={<Field name="selfAssignee" component={Checkbox} />}
                    label="Я ответственный"
                  />
                </FormControl>
                {offers && offers.length > 0 ? (
                  <FormControl margin="none">
                    <FormControlLabel
                      control={
                        <Field name="offerAssignees" component={Checkbox} />
                      }
                      label="Ответственные за листингом"
                      required
                    />
                  </FormControl>
                ) : null}
              </Grid>
            ) : null}
            {!id ? (
              <>
                <Assignees
                  label="Ответственный сотрудник"
                  team={team}
                  assignees={assignees}
                  onSelect={onAssigneeSelect}
                  onUnselect={onAssigneeUnselect}
                />
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Предложенные листинги
                  </Typography>
                  <Field
                    name="offers"
                    component={({meta}) => {
                      if (meta.error && !isObject(meta.error)) {
                        return <FormHelperText error>{meta.error}</FormHelperText>;
                      }
                      return null;
                    }}
                    margin="none"
                  />
                </Grid>
                <OfferSearch
                  id={id}
                  addresses={propertyAddresses}
                  offers={offers}
                  change={(name, value) => change(`request.${name}`, value)}
                  array={{
                    push: (name, value) => push(`request.${name}`, value),
                    remove: (name, index) => remove(`request.${name}`, index),
                  }}
                  onSelect={onOfferSelect}
                  onUnselect={onOfferUnselect}
                />
              </>
            ) : null}

            <Grid item xs={6}>
              <Field
                name="areaFrom"
                label="Площадь от (м²)"
                component={TextField}
                normalize={value => (value ? value.replace(',', '.') : value)}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="areaTo"
                label="до (м²)"
                component={TextField}
                normalize={value => (value ? value.replace(',', '.') : value)}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="priceFrom"
                label="Бюджет от (₽)"
                component={TextField}
                normalize={value => numberWithSpaces(value)}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="priceTo"
                label="до (₽)"
                component={TextField}
                normalize={value => numberWithSpaces(value)}
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={SuggestInput}
                name="activity"
                required
                label="Сфера деятельности"
                change={change}
                placeholder="Начните вводить название"
                initializeValue={activityId}
                items={activities}
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={SuggestInput}
                name="activityDetails"
                label="Детали деятельности"
                change={change}
                placeholder="Начните вводить название"
                initializeValue={activityDetailsId}
                items={activitiesDetails}
                margin="none"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="requirements"
                label="Дополнительные пожелания"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
              <FormHelperText>
                Конкретизируйте назначение и укажите дополнительные требования
              </FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="action"
                label="Действия"
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                <MenuItem value={null} />
                {requestActions.map(({value, label}, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="result"
                label="Результат"
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                <MenuItem value={null} />
                {requestResults.map(({value, label}, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            {!id ? (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    style={{marginTop: 8, marginBottom: 8}}
                  >
                    Обратная связь
                  </Typography>
                  <Field
                    name="feedback"
                    component={({meta}) => {
                      if (meta.error && !isObject(meta.error)) {
                        return <FormHelperText error>{meta.error}</FormHelperText>;
                      }
                      return null;
                    }}
                    margin="none"
                  />
                  <FormControl margin="none">
                    <FormControlLabel
                      control={<Field name="feedbackYes" component={Checkbox}/>}
                      label="Обратная связь получена"
                    />
                  </FormControl>
                  <Field
                    name="feedbackWait"
                    component={() => (
                      <CheckboxInput
                        label="Отложенный показ"
                        checked={feedbackWait}
                        onChangeValue={checked => {
                          this.setState({feedbackWait: checked});
                          change('request.feedbackWait', checked);
                        }}/>
                    )}
                  />
                  <FormControl margin="none">
                    <FormControlLabel
                      control={<Field name="feedbackNo" component={Checkbox}/>}
                      label="Обратная связь не дана"
                    />
                  </FormControl>
                  <FormControl margin="none">
                    <FormControlLabel
                      control={<Field name="feedbackViewed" component={Checkbox}/>}
                      label="Просмотр состоялся"
                    />
                  </FormControl>
                </Grid>
                {
                  feedbackWait ? (
                    <Grid item xs={12}>
                      <Field
                        name="waitingDeadline"
                        label="Дата отложенного показа"
                        format={null}
                        component={props => (
                          <DatePicker format="yyyy-MM-dd" clearable {...props} />
                        )}
                        fullWidth
                        margin="none"
                      />
                    </Grid>
                  ) : null
                }
                </>
            ) : null}
            <Grid item xs={12}>
              <Field
                name="comment"
                label="Комментарий"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
            </Grid>
          </Grid>
        </FormSection>
      </div>
    );
  }
}

export default connect(({root}) => ({
  dealSources: root.classification.dealSources,
  classificationDeal: root.classification.classificationDeal,
  offerTypes: root.classification.offerTypes,
  requestActions: root.classification.requestActions,
  requestResults: root.classification.requestResults,
  requestFeedback: root.classification.requestFeedback,
  activities: root.classification.activities,
  activitiesDetails: root.classification.activitiesDetails,
}))(Form);
