import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import RequestAssignees from './RequestAssignees';
import RequestListWrapper from './RequestListWrapper';
import RequestOffers from './RequestOffers';
import RequestView from './RequestView';
import {setTitle} from '../../actions';

class Requests extends Component {

  render() {
    const {match} = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={RequestListWrapper}/>
        <Route path={`${match.url}/:dealId/assignees`} exact component={RequestAssignees}/>
        <Route path={`${match.url}/:dealId/offers`} exact component={RequestOffers}/>
        <Route path={`${match.url}/:requestId`} component={RequestView}/>
      </Switch>
    );
  }
}

export default connect(null, {setTitle})(Requests);
