import {set, without} from 'lodash';
import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError, formValueSelector, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import Form from './form';
import {showResponseError} from '../../utils';

class RequestEdit extends Component {
  state = {
    offers: [],
    assignees: [],
  };

  componentDidMount() {
    const {initialize, data} = this.props;

    if (data) {
      initialize({request: data});
    } else {
      initialize({
        request: {
          date: new Date(),
          type: 'rent',
          propertyAddresses: [],
          offers: [],
          assignees: [],
          offerAssignees: true,
          selfAssignee: false,
          feedbackYes: false,
          feedbackWait: false,
          waitingDeadline: null,
          feedbackNo: false,
          feedbackViewed: false,
          contactName: null,
          contactPhone: null,
        },
      });
    }
  }

  render() {
    const {
      array,
      change,
      classes,
      handleSubmit,
      id,
      data,
      status,
      initialized,
      offerType,
      pristine,
      propertyAddresses,
      submitting,
      teams,
      onClose,
    } = this.props;
    const {
      offers,
      assignees,
    } = this.state;

    if (!initialized) {
      return null;
    }

    return (
      <Dialog fullScreen open classes={{paper: classes.dialog}}>
        <DialogTitle>
          Заявка
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Form
            id={id}
            array={array}
            status={status}
            change={change}
            assignees={assignees}
            team={teams[0]}
            propertyAddresses={propertyAddresses}
            offers={offers}
            activityId={data && data.activity ? data.activity : null}
            activityDetailsId={data && data.activityDetails ? data.activityDetails : null}
            offerType={offerType}
            onOfferSelect={newOffer => {
              if (this.state.offers.find(offer => offer.id === newOffer.id)) {
                return;
              }
              array.push('request.offers', newOffer.id);
              this.setState({offers: [...this.state.offers, newOffer]});
            }}
            onOfferUnselect={index => {
              array.remove('request.offers', index);
              this.setState({
                offers: without(this.state.offers, this.state.offers[index]),
              });
            }}
            onAssigneeSelect={assignee => {
              array.push('request.assignees', assignee.id);
              this.setState({assignees: [...this.state.assignees, assignee]});
            }}
            onAssigneeUnselect={index => {
              array.remove('request.assignees', index);
              this.setState({
                assignees: without(
                  this.state.assignees,
                  this.state.assignees[index]
                ),
              });
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = ({request}) => {
    const {onSubmitted, id} = this.props;
    let url = '/api/v1/createRequest';

    if (id) {
      url = '/api/v1/updateRequest';
      delete request.assignees;
      delete request.offers;
    }

    return axios
      .post(url, {id, request})
      .then(resp => onSubmitted({...request, id: resp.data.id}))
      .catch(error => {
        showResponseError(error.response, SubmissionError, set);
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

RequestEdit = withStyles(styles)(RequestEdit);

const selector = formValueSelector('request');
RequestEdit = connect(state => ({
  teams: state.root.selfUser.teams,
  propertyAddresses: selector(state, 'request.propertyAddresses'),
  selfUser: state.root.selfUser,
  dealClosesStatuses: state.root.classification.dealClosesStatuses,
}))(RequestEdit);

export default reduxForm({
  form: 'request',
})(RequestEdit);
