import React, {Component} from 'react';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as icons from '@material-ui/icons';
import axios from 'axios';

import SetOrigin from './form/SetOrigin';
import PropertyList from '../property/PropertyList';
import PropertyListItems from '../property/PropertyListItems';
import {setOfferOrigin} from '../../actions';

class OfferOrigin extends Component {
  state = {
    openItemMenu: null,
    currentItem: null,
    setOrigin: null,
    selectedItems: [],
  };

  componentDidMount() {
    this.load();
  }

  load() {
    const {match} = this.props;
    let thisOfferId = match.params.offerId;
    axios.get(`/api/offers/${thisOfferId}`)
      .then(res => {
        this.setState({selectedItems: [res.data.origin]});
      })
      .catch(() => {
        alert('Произошла ошибка');
      });
  }

  render() {
    const {history, location, match, setOfferOrigin} = this.props;
    const {
      setOrigin,
      currentItem,
      openItemMenu,
      selectedItems,
    } = this.state;

    return (
      <React.Fragment>
        <PropertyList
          history={history}
          match={match}
          location={location}
          selectedItems={selectedItems}
          action={(item, selected) => (
            <IconButton 
              disabled={selected}
              onClick={e => {
                this.setState({
                  openItemMenu: e.currentTarget,
                  currentItem: item,
                });
                e.stopPropagation();
              }}>
              <icons.MoreVert/>
            </IconButton>
          )}
        >
          <PropertyListItems/>
        </PropertyList>
        <Menu
          anchorEl={openItemMenu}
          open={Boolean(openItemMenu)}
          onClick={() => this.setState({openItemMenu: null})}
        >
          <MenuItem
            onClick={() => this.setState({
              setOrigin: {
                offerId: match.params.offerId,
                propertyId: currentItem.id,
              },
            })}
          >
            Установить объект
          </MenuItem>
        </Menu>
        {setOrigin ? (
          <SetOrigin
            onClose={() => this.setState({setOrigin: null})}
            onSubmitted={() => {
              /*if (history.length > 0) {
                history.goBack();
              }*/
              setOfferOrigin(setOrigin.offerId, setOrigin.propertyId);
              this.setState({setOrigin: null, selectedItems: [setOrigin.propertyId]});
            }}
            open={Boolean(setOrigin)}
            initialValues={setOrigin}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect(null, {setOfferOrigin})(OfferOrigin);
