import React, {Component} from 'react';
import {connect} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';

import OfferDetailsWrapper from './OfferDetailsWrapper';
import Team from './relations/Team';
import Assignees from './relations/Assignees';
import Deals from './relations/Deals';
import DealOffers from './relations/DealOffers';
import Documents from './relations/Documents';
import Siblings from './relations/Siblings';
import Origin from './relations/Origin';
import {loadOffer, setOfferComment} from '../../actions';
import {loadProperty} from '../../actions';
import Row from '../../components/Row';
import Column from '../../components/Column';
import EditableComment from '../../components/EditableComment';
import Owner from './relations/Owner';

import OfferDialog from '../offer/OfferDialog';
import AssigneeDialog from '../contacts/AssigneeDialog';
import PropertyDialog from '../property/PropertyDialog';
import DealOfferDialog from '../dealOffers/DealOfferDialog';
import DealDialog from '../deals/DealDialog';
import {queryOfferDealOffersPreview} from '../../queries/DealOffers';
import MiddleColumn from '../../components/MiddleColumn';
import {getOfferWarningList} from '../../utils/alerts';
import getScreensView from '../../utils/getScreensView';

class OfferView extends Component {
  state = {
    openPropertyDialog: false,
    openOfferDialog: false,
    openAssigneeDialog: false,
    openDealDialog: false,
    openDealOfferDialog: false,
    offerViewCache: null,
    locationKey: null,
    dealOfferLoading: false,
    dealOfferError: false,
    dealOffers: [],
    dealOffersActive: [],
    openContactDialog:false,
  };

  componentDidMount() {
    this.props.loadOffer(this.props.location.key, this.props.match.params.offerId);
    this.getDealOffers();
    if (this.props.offerView) {
      this.setState({
        offerViewCache: this.props.offerView,
        locationKey: this.props.location.key,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.match.params.offerId !== this.props.match.params.offerId) {
      this.props.loadOffer(newProps.location.key, newProps.match.params.offerId);
      this.setState({
        openOfferDialog: false,
      });
    }
    if (newProps.offerView && newProps.offerView !== this.props.offerView && newProps.location.key !== undefined) {
      this.setState({offerViewCache: newProps.offerView});
    }
  }

  handleDialogClose = name => {
    this.setState({[name]: false});
  };

  handleDialogOpen = (value, name) => {
    this.setState({[name] : value});
  };

  getDealOffers = () => {
    const {match} = this.props;
    this.setState({dealOfferLoading: true});
    queryOfferDealOffersPreview({source: 'ALL', filter: {offer: match.params.offerId}})
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          let dealOfferArrays = this.divideDealOffers(resp.data.data.dealOfferList.items);
          this.setState({dealOffersActive: dealOfferArrays[0], dealOffers: dealOfferArrays[1], dealOfferLoading: false});
          return;
        }
        this.setState({dealOfferLoading: false, dealOfferError: true});
      })
      .catch(() => {
        this.setState({dealOfferLoading: false, dealOfferError: true});
      });
  };

  divideDealOffers = dealOfferArray => {
    return dealOfferArray.reduce(([dealOffersActive, dealOffers], elem) => {
      return elem.dealStartAt ? [[...dealOffersActive, elem], dealOffers] : [dealOffersActive, [...dealOffers, elem]];
    }, [[], []]);
  };

  render() {
    const {
      classes,
      offerView,
      offers,
      setOfferComment,
      history,
      properties,
    } = this.props;

    const {
      openAssigneeDialog,
      openOfferDialog,
      openPropertyDialog,
      openDealOfferDialog,
      openDealDialog,
      dealOfferLoading,
      dealOfferError,
      dealOffers,
      dealOffersActive,
      offerViewCache,
    } = this.state;

    if (!offerView && !offerViewCache) {
      return null;
    }

    if (offerView && offerView.loading) {
      return <CircularProgress size={50} thickness={4} className={classes.progress}/>;
    }
    if (offerView && offerView.error) {
      return <icons.ErrorOutline className={classes.progress}/>;
    }

    const offer = offerView && offerView.item ? offers[offerView.item] : offers[offerViewCache.item];
    const property = properties[offer.origin] ? properties[offer.origin] : null;

    return (
      <React.Fragment>
        <Row>
          <Column label="Информация" icon={<icons.InfoOutlined/>} key="information">
            <OfferDetailsWrapper offer={offer}/>
          </Column>
          <Column label="История" icon={<icons.History/>} key="activity">
            <div className="column" style={{background: colors.grey[200], zIndex: 0}}>
              <div className="content" style={{padding: 16}}>
                {getOfferWarningList(offer)}
                {offer ? (
                  <MiddleColumn entityId={offer.id} entityName="Offer">
                    <EditableComment
                      comment={offer.comment}
                      onSave={comment => setOfferComment(offer.id, comment, offer.comment)}
                    />
                  </MiddleColumn>
                ) : null}
              </div>
            </div>
          </Column>
          <Column label="Связи" icon={<icons.Share/>} key="relationships">
            <Paper elevation={1} square className="column">
              <div className="content">
                <Team offer={offer} />
                <Divider/>
                <Assignees offer={offer} handleDialogOpen={e => this.handleDialogOpen(e, 'openAssigneeDialog')}/>
                <Divider/>
                <Owner property={property} handleDialogOpen={e => this.handleDialogOpen(e, 'openContactDialog')}/>
                <Divider/>
                <Origin offer={offer} handleDialogOpen={e => this.handleDialogOpen(e, 'openPropertyDialog')}/>
                <Divider/>
                <Documents offer={offer}/>
                <Divider/>
                <DealOffers
                  loading={dealOfferLoading}
                  error={dealOfferError}
                  dealOffers={dealOffersActive}
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openDealOfferDialog')}
                />
                <Divider/>
                <Siblings offer={offer} handleDialogOpen={e => this.handleDialogOpen(e, 'openOfferDialog')}/>
                <Divider/>
                <Deals
                  history={history}
                  dealOffers={dealOffers}
                  loading={dealOfferLoading}
                  error={dealOfferError}
                  offer={offer}
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openDealDialog')}
                />
                <Divider/>
              </div>
            </Paper>
          </Column>
        </Row>
        {openOfferDialog ? (
          <OfferDialog
            history={history}
            offer={openOfferDialog}
            open={Boolean(openOfferDialog)}
            handleClose={() => this.handleDialogClose('openOfferDialog')}
          />
        ) : null}
        {openAssigneeDialog ? (
          <AssigneeDialog
            history={history}
            member={openAssigneeDialog}
            open={Boolean(openAssigneeDialog)}
            handleClose={() => this.handleDialogClose('openAssigneeDialog')}
          />
        ) : null}
        {openPropertyDialog ? (
          <PropertyDialog
            history={history}
            property={openPropertyDialog}
            open={Boolean(openPropertyDialog)}
            handleClose={() => this.handleDialogClose('openPropertyDialog')}
          />
        ) : null}
        {openDealDialog ? (
          <DealDialog
            history={history}
            deal={openDealDialog}
            open={Boolean(openDealDialog)}
            handleClose={() => this.handleDialogClose('openDealDialog')}
            reload={() => this.getDealOffers()}
          />
        ) : null}
        {openDealOfferDialog ? (
          <DealOfferDialog
            history={history}
            dealOffer={openDealOfferDialog}
            open={Boolean(openDealOfferDialog)}
            handleClose={() => this.handleDialogClose('openDealOfferDialog')}
            reload={() => this.getDealOffers()}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = () => ({
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: 50,
    height: 50,
    marginTop: -25,
    marginLeft: -25,
    color: colors.grey[500],
  },
});

export default connect(
  (state, ownProps) => ({
    offerView: getScreensView(state.root.offer.screens, ownProps.location.key),
    screens: state.root.offer.screens,
    offers: state.root.offer.offers,
    selfUser: state.root.selfUser,
    properties: state.root.property.properties,
  }),
  {
    loadOffer,
    loadProperty,
    setOfferComment,
  },
)(withStyles(styles)(OfferView));
