import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import TeamListItem from '../../../components/relations/TeamListItem';

class Team extends Component {
  render() {
    const {
      show,
      team,
    } = this.props;

    if (!show || !team) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Команда
        </ListSubheader>
      }>
        {team.id && team.name ? (
          <TeamListItem
            key={team.id}
            name={team.name}
          />
        ) : null}
      </List>
    );
  }
}

export default withRouter(Team);
