import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, FormSection} from 'redux-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import ContactField from '../../contacts/form/ContactField';
import TextField from '../../../components/Input/TextField';
import CityInput from '../../../components/Input/CityInput';
import {queryCities} from '../../../queries/CityQuery';
import Photos from './Photos';
import {Checkbox} from 'redux-form-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as icons from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

class Form extends Component {
  render() {
    const {
      array: {push, remove, swap},
      array,
      change,
      contact,
      id,
      onContactCreate,
      onContactUnassign,
      onContactSelect,
      team,
      netRenterTypes,
      cityHint,
      city,
      logos,
      classes,
    } = this.props;

    return (
      <div style={{marginTop: 24}}>
        <FormSection name="netRenter">
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Field
                component={CityInput}
                name="city"
                label="Город"
                required
                change={change}
                placeholder="Введите название города"
                hint={team.cities || cityHint}
                query={queryCities}
                initializeValue={city ? city.id : null}
                useObject
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="trademark"
                label="Торговая марка"
                component={TextField}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Photos
                name="logos"
                label="Логотип"
                array={array}
                photos={logos}
                push={(name, value) => push('netRenter.' + name, value)}
                remove={(name, value) => remove('netRenter.' + name, value)}
                swap={(name, oldIndex, newIndex) => swap('netRenter.' + name, oldIndex, newIndex)}
              />
            </Grid>
            {!id ? (
              <React.Fragment>
                <ContactField
                  contact={contact}
                  onContactCreate={onContactCreate}
                  onContactUnassign={onContactUnassign}
                  onContactSelect={onContactSelect}
                />
                <Field
                  name="contact"
                  component={({meta}) => {
                    if (meta.error) {
                      return (
                        <FormHelperText error style={{paddingLeft: 12}}>
                          {meta.error}
                        </FormHelperText>
                      );
                    }
                    return null;
                  }}
                />
              </React.Fragment>
            ) : null}
            <Grid item xs={12}>
              <Field
                name="type"
                label="Вид деятельности"
                required
                component={TextField}
                select
                fullWidth
                margin="none"
              >
                {netRenterTypes.map(({value, label}, i) => (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="publicRequest"
                label="Публичные требования"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
              <FormHelperText>
                Конкретизируйте назначение и укажите требования (открытая информация)
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="privateRequest"
                label="Закрытые требования"
                component={TextField}
                multiline
                rowsMax={15}
                fullWidth
                margin="none"
              />
              <FormHelperText>
                Конкретизируйте назначение и укажите требования (закрытая информация)
              </FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="areaFrom"
                label="Площадь от (м²)"
                component={TextField}
                normalize={value =>
                  value ? value.toString().replace(',', '.') : value
                }
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="areaTo"
                label="до (м²)"
                component={TextField}
                normalize={value =>
                  value ? value.toString().replace(',', '.') : value
                }
                fullWidth
                margin="none"
              />
            </Grid>
            <Grid item xs={12} md={4} style={{display: 'flex', alignItems: 'center'}}>
              <FormControlLabel
                control={<Field name="private" component={Checkbox} />}
                label="Закрытый"
                required
              />
              <Tooltip
                enterTouchDelay={0}
                title={
                  <div>
                    <p>Арендатор не будет выгружаться на сайт, а уже выгруженный будет скрыт.</p>
                  </div>
                }
                style={{display: 'block'}}
              >
                <icons.Help className={classes.help} />
              </Tooltip>
            </Grid>
          </Grid>
        </FormSection>
      </div>
    );
  }
}

const styles = {
  help: {
    color: colors.grey[700],
    marginLeft: 12,
  },
};

export default connect(state => ({
  netRenterTypes: state.root.classification.netRenterTypes,
  cityHint: Object.values(state.root.hint.city),
}))(withStyles(styles)(Form));
