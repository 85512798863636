
//Задачи
export const TITLE_SET_TO_TASK = 'Arr\\Is\\Domain\\Event\\TitleSetToTask';
export const DESCRIPTION_SET_TO_TASK = 'Arr\\Is\\Domain\\Event\\DescriptionSetToTask';
export const AUTHOR_SET_TO_TASK = 'Arr\\Is\\Domain\\Event\\AuthorSetToTask';
export const COMMENT_ADDED_TO_TASK = 'Arr\\Is\\Domain\\Event\\CommentAddedToTask';
export const COWORKER_ADDED_TO_TASK = 'Arr\\Is\\Domain\\Event\\CoworkerAddedToTask';
export const COWORKER_REMOVED_FROM_TASK = 'Arr\\Is\\Domain\\Event\\CoworkerRemovedFromTask';
export const OBSERVER_ADDED_TO_TASK = 'Arr\\Is\\Domain\\Event\\ObserverAddedToTask';
export const OBSERVER_REMOVED_FROM_TASK = 'Arr\\Is\\Domain\\Event\\ObserverRemovedFromTask';
export const TASK_CLOSED = 'Arr\\Is\\Domain\\Event\\TaskClosed';
export const TASK_CREATED = 'Arr\\Is\\Domain\\Event\\TaskCreated';
export const TASK_REOPENED = 'Arr\\Is\\Domain\\Event\\TaskReopened';
export const TASK_RESCHEDULED = 'Arr\\Is\\Domain\\Event\\TaskRescheduled';
export const DOCUMENT_ADDED_TO_TASK = 'Arr\\Is\\Domain\\Event\\DocumentAddedToTask';
export const DOCUMENT_REMOVED_FROM_TASK = 'Arr\\Is\\Domain\\Event\\DocumentRemovedFromTask';

//старые
export const TASK_REASSIGNED = 'Arr\\Is\\Domain\\Event\\TaskReassigned';

// Contracts events

export const CONTRACT_CREATED = 'Arr\\Is\\Domain\\Event\\ContractCreated';
export const CONTRACT_ARCHIVED = 'Arr\\Is\\Domain\\Event\\ContractArchived';
export const CONTRACT_ADDED_TO_PROPERTY = 'Arr\\Is\\Domain\\Event\\ContractAddedToProperty';
export const CONTRACT_REMOVED_FROM_PROPERTY = 'Arr\\Is\\Domain\\Event\\ContractRemovedFromProperty';
export const CONTRACT_ADDITIONAL_AGREEMENT = 'Arr\\Is\\Domain\\Event\\AdditionalAgreementsSetToContract';
export const CONTRACT_TYPE_SET = 'Arr\\Is\\Domain\\Event\\TypeSetToContract';
export const CONTRACT_COMMISSION_SET = 'Arr\\Is\\Domain\\Event\\CommissionSet';
export const CONTRACT_NUMBER_SET = 'Arr\\Is\\Domain\\Event\\NoSetToContract';
export const CONTRACT_START_SET = 'Arr\\Is\\Domain\\Event\\StartAtSet';
export const CONTRACT_END_SET = 'Arr\\Is\\Domain\\Event\\EndAtSet';
export const CONTRACT_RENEWAL_TERMS_SET = 'Arr\\Is\\Domain\\Event\\RenewalTermsSetToContract';

// старые

export const COMMENT_ADDED_TO_CONTRACT = 'Arr\\Is\\Domain\\Event\\CommentAddedToContract';
export const ASSIGNEE_ADDED_TO_CONTRACT = 'Arr\\Is\\Domain\\Event\\AssigneeAddedToContract';
export const ASSIGNEE_REMOVED_FROM_CONTRACT = 'Arr\\Is\\Domain\\Event\\AssigneeRemovedFromContract';
export const CONTACT_CHANGED_IN_CONTRACT = 'Arr\\Is\\Domain\\Event\\ContactChangedInContract';
export const DOCUMENT_ADDED_TO_CONTRACT = 'Arr\\Is\\Domain\\Event\\DocumentAddedToContract';
export const DOCUMENT_REMOVED_FROM_CONTRACT = 'Arr\\Is\\Domain\\Event\\DocumentRemovedFromContract';

// Заявки

export const OFFER_PRICE_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\OfferPriceSetToDeal';
export const OFFER_TYPE_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\OfferTypeSetToDeal';
export const SOURCE_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\SourceSetToDeal';
export const ABC_CLASS_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\ABCClassSetToDeal';
export const PROPERTY_TYPE_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\PropertyTypeSetToDeal';
export const PROPERTY_ADDITIONAL_TYPES_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\PropertyAdditionalTypesSetToDeal';
export const PROPERTY_PURPOSE_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\PropertyPurposeSetToDeal';
export const PROPERTY_AREA_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\PropertyAreaSetToDeal';
export const OFFER_ADDED_TO_DEAL = 'Arr\\Is\\Domain\\Event\\OfferAddedToDeal';
export const OFFER_REMOVED_FROM_DEAL = 'Arr\\Is\\Domain\\Event\\OfferRemovedFromDeal';
export const REQUIREMENTS_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\RequirementsSetToDeal';
export const DEAL_FREE = 'Arr\\Is\\Domain\\Event\\DealFree';
export const DEAL_CREATED = 'Arr\\Is\\Domain\\Event\\DealCreated';
export const CLOSE_STATUS_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\CloseStatusSetToDeal';
export const DEAL_DECLINED_OFFER = 'Arr\\Is\\Domain\\Event\\DealDeclinedOffer';
export const MULTIPLE_SET_TO_DEAL = 'Arr\\Is\\Domain\\Event\\MultipleSetToDeal';
export const DEAL_OFFERS_STATUS_SET  = 'Arr\\Is\\Domain\\Event\\DealOfferStatusSet';
export const DEAL_OFFERS_SELECTION_CREATED  = 'Arr\\Is\\Domain\\Event\\DealOffersSelectionCreated';
export const DEAL_OFFERS_SELECTION_CHANGED  = 'Arr\\Is\\Domain\\Event\\DealOffersSelectionChanged';
export const DEAL_OFFERS_SELECTION_EMAIL_SENT  = 'Arr\\Is\\Domain\\Event\\DealOffersSelectionEmailSent';

// Заявки (Request)
export const REQUEST_CREATED = 'Arr\\Is\\Domain\\Event\\RequestCreated';
export const REQUEST_SOURCE_SET = 'Arr\\Is\\Domain\\Event\\RequestSourceSet';
export const REQUEST_TYPE_SET = 'Arr\\Is\\Domain\\Event\\RequestTypeSet';
export const REQUEST_ACTION_SET = 'Arr\\Is\\Domain\\Event\\RequestActionSet';
export const REQUEST_RESULT_SET = 'Arr\\Is\\Domain\\Event\\RequestResultSet';
export const REQUEST_REQUIREMENTS_SET = 'Arr\\Is\\Domain\\Event\\RequestRequirementsSet';
export const REQUEST_FEEDBACK_CHANGED = 'Arr\\Is\\Domain\\Event\\RequestFeedbackChanged';
export const REQUEST_CONTACT_NAME_CHANGED = 'Arr\\Is\\Domain\\Event\\RequestContactNameChanged';
export const REQUEST_CONTACT_PHONE_CHANGED = 'Arr\\Is\\Domain\\Event\\RequestContactPhoneChanged';
export const REQUEST_ACTIVITY_CHANGED = 'Arr\\Is\\Domain\\Event\\RequestActivityChanged';
export const REQUEST_ACTIVITY_DETAILS_CHANGED = 'Arr\\Is\\Domain\\Event\\RequestActivityDetailsChanged';
export const REQUEST_AREA_CHANGED = 'Arr\\Is\\Domain\\Event\\RequestAreaChanged';
export const REQUEST_PRICE_CHANGED = 'Arr\\Is\\Domain\\Event\\RequestPriceChanged';

//Сделки
export const DEAL_OFFER_CREATED = 'Arr\\Is\\Domain\\Event\\DealOfferCreated';
export const DEAL_OFFER_CLOSED = 'Arr\\Is\\Domain\\Event\\DealOfferClosed';
export const DEAL_OFFER_DECLINED = 'Arr\\Is\\Domain\\Event\\DealOfferDeclined';
export const ADDITIONAL_SET_TO_DEAL_OFFER = 'Arr\\Is\\Domain\\Event\\AdditionalSetToDealOffer';
export const POTENTIAL_END_AT_SET_TO_DEAL_OFFER = 'Arr\\Is\\Domain\\Event\\PotentialEndAtSetToDealOffer';

//универсальные

export const COMMENT_ADDED = 'Arr\\Is\\Domain\\Event\\CommentSet';
export const ASSIGNEE_ADDED = 'Arr\\Is\\Domain\\Event\\AssigneeAdded';
export const ASSIGNEE_SET = 'Arr\\Is\\Domain\\Event\\AssigneeSet';
export const ASSIGNEE_REMOVED = 'Arr\\Is\\Domain\\Event\\AssigneeRemoved';
export const DOCUMENT_ADDED = 'Arr\\Is\\Domain\\Event\\DocumentAdded';
export const DOCUMENT_REMOVED = 'Arr\\Is\\Domain\\Event\\DocumentRemoved';
export const CONTACT_CHANGED = 'Arr\\Is\\Domain\\Event\\ContactChanged';
export const CONTACT_PHONE_CREATED = 'Arr\\Is\\Domain\\Event\\ContactPhoneCreated';
export const CONTACT_PHONE_CHANGED = 'Arr\\Is\\Domain\\Event\\ContactPhoneChanged';
export const STATUS_SET = 'Arr\\Is\\Domain\\Event\\StatusSet';
export const DEADLINE_SET = 'Arr\\Is\\Domain\\Event\\DeadlineSet';

//старые

export const CREATED = 'Arr\\Is\\Domain\\Event\\Created';



export const assigneeUpdateArray = [
  {type: ASSIGNEE_ADDED_TO_CONTRACT, id: 'assigneeId'},
  {type: ASSIGNEE_ADDED, id: 'assigneeId'},
  {type: ASSIGNEE_SET, id: 'id'},
  {type: ASSIGNEE_REMOVED, id: 'assigneeId'},
  {type: DEAL_CREATED, id: 'assignees'},
  {type: CONTRACT_CREATED, id: 'assignees'},
  {type: ASSIGNEE_REMOVED_FROM_CONTRACT, id: 'assigneeId'},
  {type: TASK_REASSIGNED, id: 'assigneeId'},
  {type: COWORKER_ADDED_TO_TASK, id: 'coworkerId'},
  {type: COWORKER_REMOVED_FROM_TASK, id: 'coworkerId'},
  {type: OBSERVER_ADDED_TO_TASK, id: 'observerId'},
  {type: OBSERVER_REMOVED_FROM_TASK, id: 'observerId'},
];

export const contactUpdateArray = [
  {type: CONTACT_CHANGED_IN_CONTRACT, id: 'contactId'},
  {type: CONTACT_CHANGED, id: 'contactId'},
  {type: DEAL_CREATED, id: 'contact'},
  {type: CONTRACT_CREATED, id: 'contact'},
];
export const propertyUpdateArray = [
  {type: CONTRACT_ADDED_TO_PROPERTY, id: 'propertyId'},
  {type: CONTRACT_REMOVED_FROM_PROPERTY, id: 'propertyId'},
  {type: CONTRACT_CREATED, id: 'properties'},
];

export const offerUpdateArray = [
  {type: DEAL_OFFER_CREATED, id: 'offer'},
  {type: OFFER_ADDED_TO_DEAL, id: 'offer'},
  {type: OFFER_REMOVED_FROM_DEAL, id: 'offer'},
  {type: DEAL_CREATED, id: 'offers'},
  {type: DEAL_OFFERS_SELECTION_CREATED, id: 'offers'},
  {type: DEAL_OFFERS_SELECTION_CHANGED, id: 'offers'},
];

export const arrayNames = {
  contact: 'contacts',
  assignee: 'members',
  property: 'properties',
  offer: 'offer',
};
