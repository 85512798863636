import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';

import UnsetOrigin from '../form/UnsetOrigin';
import {unsetOfferOrigin, unsetOffer} from '../../../actions';
import {createAreaFormatter, mapLabels} from '../../../utils';

import PropertyListItem from '../../../components/relations/PropertyListItem';

class Origin extends Component {
  state = {
    openMenu: false,
    unsetOrigin: false,
  };

  render() {
    const {
      history,
      offer,
      properties,
      unsetOfferOrigin,
      handleDialogOpen,
      unsetOffer,
    } = this.props;
    const {openMenu, unsetOrigin} = this.state;

    if (!offer) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader disableSticky style={{position: 'relative'}}>
          Объект
          <ListItemSecondaryAction>
            <IconButton onClick={() => history.push(`/offers/${offer.id}/origin`)}>
              <icons.Add/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {offer.origin && properties[offer.origin] ? (
          <PropertyListItem
            property={properties[offer.origin]}
            onSecondaryActionClick={e => this.setState({openMenu: {target: e.currentTarget}})}
            handleDialogOpen={e => handleDialogOpen(e)}
          />
        ) : null}
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => {
              this.setState({
                unsetOrigin: {offer: offer, offerId: offer.id},
              });
            }}>
              Убрать объект
            </MenuItem>
          </Menu>
        ) : null}
        {unsetOrigin ? (
          <UnsetOrigin
            open={Boolean(unsetOrigin)}
            initialValues={unsetOrigin}
            onSubmitted={() => {
              unsetOfferOrigin(unsetOrigin.offerId);
              unsetOffer(unsetOrigin.offer);
              this.setState({unsetOrigin: false});
            }}
            onClose={() => this.setState({unsetOrigin: false})}
          />
        ) : null}
      </List>
    );
  }
}

Origin = connect(
  state => ({
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    properties: state.root.property.properties,
  }),
  {
    unsetOfferOrigin,
    unsetOffer,
  }
)(Origin);

export default withRouter(Origin);
