import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import * as icons from '@material-ui/icons';

import RemoveAssignee from '../form/RemoveAssignee';
import {deleteAssigneeFromOffer} from '../../../actions';
import MemberListItem from '../../../components/relations/MemberListItem';

class Assignees extends Component {
  state = {
    openMenu: false,
    removeAssignee: false,
  };

  render() {
    const {
      deleteAssigneeFromOffer,
      members,
      history,
      offer,
      handleDialogOpen,
    } = this.props;
    const {openMenu, removeAssignee} = this.state;

    if (!offer) {
      return null;
    }

    return (
      <List subheader={
        <ListSubheader
          disableSticky
          style={{position: 'relative'}}
        >
          Ответственные
          <ListItemSecondaryAction>
            <IconButton onClick={() => history.push(`/offers/${offer.id}/assignees`)}>
              <icons.Add/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListSubheader>
      }>
        {offer.assignees ? offer.assignees.map(assignee => {
          const member = members[assignee.id];
          return member ? (
            <MemberListItem
              key={assignee.id}
              member={member}
              onSecondaryActionClick={
                offer.assignees.length > 1
                  ? e => this.setState({openMenu: {target: e.currentTarget, id: assignee.id}})
                  : null
              }
              handleDialogOpen={e => handleDialogOpen(e)}
            />
          ) : null;
        }) : null}
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => {
              this.setState({
                removeAssignee: {
                  offerId: offer.id,
                  assigneeId: openMenu.id,
                },
              });
            }}>
              Снять ответственного
            </MenuItem>
          </Menu>
        ) : null}
        {removeAssignee ? (
          <RemoveAssignee
            open={Boolean(removeAssignee)}
            initialValues={removeAssignee}
            onSubmitted={() => {
              deleteAssigneeFromOffer(removeAssignee.offerId, removeAssignee.assigneeId);
              this.setState({removeAssignee: false});
            }}
            onClose={() => this.setState({removeAssignee: false})}
          />
        ) : null}
      </List>
    );
  }
}

Assignees = connect(
  state => ({
    members: state.root.team.members,
  }),
  {
    deleteAssigneeFromOffer,
  }
)(Assignees);

export default withRouter(Assignees);
