import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';

import PropertyAttributes from '../property/PropertyAttributes';
import {
  calculateOfferCommissionFull, copyToClipboard, copyToClipboardFeedback,
  createAreaFormatter,
  createPriceFormatter,
  formatDate,
  getImageUrl,
  ImageFilters,
  mapLabels,
} from '../../utils';
import {openLightbox, setOfferStatus, updateOffer} from '../../actions';
import EditableStatus from '../../components/EditableStatus';
import {queryDistricts, querySubDistricts} from '../../queries/CityQuery';
import {getListItems} from '../../utils/filter';
import Tooltip from '@material-ui/core/Tooltip';
import ArchiveOfferDialog from './form/ArchiveOfferDialog';

class OfferDetails extends Component {
  componentDidMount() {
    const {offer} = this.props;
    if (offer.property.address) {
      if (offer.property.address.district)
        getListItems(queryDistricts, 'id', offer.property.address.district)
          .then(res => {
            this.setState({district: res.data.data.districts.items[0].name});
          });
      if (offer.property.address.microDistrict)
        getListItems(querySubDistricts, 'id', offer.property.address.microDistrict)
          .then(res => {
            this.setState({subDistrict: res.data.data.microDistricts.items[0].name});
          },);
    }
  }

  state = {
    district: null,
    subDistrict: null,
    idCopied: false,
  };

  getAvitoReportStatus(report) {
    let status = [], errors = 0, warnings = 0;

    if (report.status) {
      status.push(this.props.avitoAdStatuses[report.status] || '');
    }

    if (report.messages && report.messages.length > 0) {
      report.messages.forEach(message => {
        if (message.type === 'error') {
          errors++;
        } else if (message.type === 'warning' || message.type === 'alarm') {
          warnings++;
        }
      });
    }

    if (errors) {
      status.push(<span key={1} className={this.props.classes.avitoReportBadge + ' _errors'} title="Ошибок">{errors}</span>);
    }

    if (warnings) {
      status.push(
        <span key={2} className={this.props.classes.avitoReportBadge + ' _warnings'} title="Предупреждений">{warnings}</span>
      );
    }

    return status;
  }

  render() {
    const {
      classes,
      openLightbox,
      offer,
      formatArea,
      formatPrice,
      offerExportLabels,
      offerStatusLabels,
      offerSourceLabels,
      offerArchiveReasonLabels,
      propertyTypeLabels,
      propertyTypes,
      propertyPurposeLabels,
      setOfferStatus,
      offerStatuses,
    } = this.props;
    const {district, subDistrict, idCopied, archiveOfferDialogOpened} = this.state;
    if (!offer) {
      return null;
    }

    const additionalTypes = offer.property.additionalTypes ? offer.property.additionalTypes.map(value => propertyTypeLabels[value] || value) : [];
    const purposes = offer.property.purposes ? offer.property.purposes.map(value => propertyPurposeLabels[value] || value) : [];
    const ignoreExports = offer.ignoreExports ? offer.ignoreExports.map(value => offerExportLabels[value] || value) : [];

    return (
      <div className="content" style={{background: 'white'}}>
        <List>
          <ListItem dense>
            <ListItemText
              primary={`${formatDate(offer.createdAt)} / ${formatDate(offer.updatedAt)}`}
              secondary="Дата создания / редактирования"
            />
          </ListItem>
          {offer.source ? (
            <ListItem dense>
              <ListItemText
                primary={offerSourceLabels[offer.source] || '-'}
                secondary="Источник"
              />
            </ListItem>
          ) : null}
          {offer.status ? (
            <EditableStatus
              status={offer.status}
              onChange={status => {
                if (status === 'archived') {
                  this.setState({archiveOfferDialogOpened: true});
                } else {
                  setOfferStatus(offer.id, status, offer.status, offer.origin, offer.archiveReason);
                }
              }}
              statuses={offerStatuses}
              statusLabels={offerStatusLabels}
            />
          ) : null}
          {offer.archiveReason ? (
            <ListItem dense>
              <ListItemText
                primary={offerArchiveReasonLabels[offer.archiveReason] || '-'}
                secondary="Причина архивации"
              />
            </ListItem>
          ) : null}
          {offer.private ? (
            <ListItem dense>
              <ListItemText
                primary="Закрытый"
                secondary="Да"
              />
            </ListItem>
          ) : null}
          {offer.name ? (
            <ListItem dense>
              <ListItemText primary={offer.name} secondary="Название"/>
            </ListItem>
          ) : null}
          {!offer.private ? (
            <ListItem
              className={classes.externalLinkListItem}
              dense
              button
              component="a"
              href={`https://arrpro.ru/objects/getbyid.php?external_id=${offer.id}`}
              target="_blank"
            >
              <ListItemText primary="arrpro.ru" secondary="Листинг на сайте"/>
              <icons.Link className={classes.externalLinkIcon}/>
            </ListItem>
          ) : null}
          {offer.avitoReport ? (
            offer.avitoReport.url ? (
              <ListItem
                className={classes.externalLinkListItem}
                dense
                button
                component="a"
                href={offer.avitoReport.url}
                target="_blank"
              >
                <ListItemText primary="Авито" secondary={this.getAvitoReportStatus(offer.avitoReport)}/>
                <icons.Link className={classes.externalLinkIcon}/>
              </ListItem>
            ) : (
              <ListItem dense>
                <ListItemText primary="Авито" secondary={this.getAvitoReportStatus(offer.avitoReport)}/>
              </ListItem>
            )
          ) : null}
          {offer.property.address ? (
            <React.Fragment>
              <ListItem dense>
                <ListItemText primary={offer.property.address.value} secondary="Адрес"/>
              </ListItem>
              {district ? (
                <ListItem dense>
                  <ListItemText primary={district} secondary="Район"/>
                </ListItem>
              ) : null}
              {subDistrict ? (
                <ListItem dense>
                  <ListItemText primary={subDistrict} secondary="Микрорайон"/>
                </ListItem>
              ) : null}
              {offer.property.address.street ? (
                <ListItem dense>
                  <ListItemText primary={offer.property.address.street} secondary="Улица"/>
                </ListItem>
              ) : null}
              {offer.property.address.building ? (
                <React.Fragment>
                  {offer.property.address.building.number ? (
                    <ListItem dense>
                      <ListItemText primary={offer.property.address.building.number} secondary="Номер дома"/>
                    </ListItem>
                  ) : null}
                  {offer.property.address.building.block ? (
                    <ListItem dense>
                      <ListItemText primary={offer.property.address.building.block} secondary="Корпус"/>
                    </ListItem>
                  ) : null}
                  {offer.property.address.building.letter ? (
                    <ListItem dense>
                      <ListItemText primary={offer.property.address.building.letter} secondary="Строение"/>
                    </ListItem>
                  ) : null}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : null}
          {additionalTypes.length ? (
            <ListItem dense>
              <ListItemText primary={additionalTypes.join(', ')} secondary="Дополнительные категории"/>
            </ListItem>
          ) : null}
          {purposes.length ? (
            <ListItem dense>
              <ListItemText primary={purposes.join(', ')} secondary="Назначение"/>
            </ListItem>
          ) : null}
          <ListItem dense>
            <ListItemText primary={formatArea(offer.property.area)} secondary="Площадь"/>
          </ListItem>
          <ListItem dense>
            <ListItemText primary={formatPrice(offer.price)} secondary="Цена"/>
          </ListItem>
          {offer.priceHigh ? (
            <ListItem dense>
              <ListItemText primary={'Цена завышена'} secondary="Характеристика цены"/>
            </ListItem>
          ) : null}
          {offer.revenue ? (
            <ListItem dense>
              <ListItemText
                primary={`${calculateOfferCommissionFull(offer.revenue, offer.price, offer.property.area)} ₽ / ${offer.revenue} %`}
                secondary="Комиссия / процент от сделки"/>
            </ListItem>
          ) : null}
          {offer.commission && !offer.revenue ? (
            <ListItem dense>
              <ListItemText primary={`${offer.commission.amount} ₽`} secondary="Комиссия партнера"/>
            </ListItem>
          ) : null}
          <PropertyAttributes
            propertyType={offer.property.type}
            propertyTypes={propertyTypes}
          />
          {ignoreExports.length ? (
            <ListItem dense>
              <ListItemText primary={ignoreExports.join(', ')} secondary="Не выгружать на площадки"/>
            </ListItem>
          ) : null}
        </List>
        {offer.property.photos && offer.property.photos.length > 0 ? (
          <div className={classes.photos}>
            <Typography variant="caption" gutterBottom>Фотографии</Typography>
            <GridList cellHeight={60} cols={5} spacing={4}>
              {offer.property.photos.map((photo, i) => (
                <GridListTile key={i} onClick={() => openLightbox(offer.property.photos, i)}>
                  <img src={getImageUrl({filter: ImageFilters.crop, width: 100, height: 100, path: photo})} alt=""/>
                </GridListTile>
              ))}
            </GridList>
          </div>
        ) : null}
        {offer.property.layouts && offer.property.layouts.length > 0 ? (
          <div className={classes.photos}>
            <Typography variant="caption" gutterBottom>Планировки</Typography>
            <GridList cellHeight={60} cols={5} spacing={4}>
              {offer.property.layouts.map((layout, i) => (
                <GridListTile key={i} onClick={() => openLightbox(offer.property.layouts, i)}>
                  <img src={getImageUrl({filter: ImageFilters.crop, width: 100, height: 100, path: layout})} alt=""/>
                </GridListTile>
              ))}
            </GridList>
          </div>
        ) : null}
        <CardContent>
          {offer.prologue ? (
            <React.Fragment>
              <Typography variant="caption" gutterBottom>Заголовок</Typography>
              <Typography
                style={{whiteSpace: 'pre-line', marginBottom: 16}}
                gutterBottom>{offer.prologue}</Typography>
            </React.Fragment>
          ) : null}
          {offer.property.description ? (
            <React.Fragment>
              <Typography variant="caption" gutterBottom>Описание</Typography>
              <Typography style={{whiteSpace: 'pre-line', marginBottom: 16}}>{offer.property.description}</Typography>
            </React.Fragment>
          ) : null}
          {offer.epilogue ? (
            <React.Fragment>
              <Typography variant="caption" gutterBottom>Подпись</Typography>
              <Typography
                style={{whiteSpace: 'pre-line', marginBottom: 16}}
                gutterBottom>{offer.epilogue}</Typography>
            </React.Fragment>
          ) : null}
        </CardContent>
        {offer.id ? (
          <ListItem
            button
            dense
            style={{marginBottom: 20}}
            onClick={
              () => {
                copyToClipboard(offer.id).then(() => {
                  copyToClipboardFeedback(
                    () => this.setState({idCopied: true}),
                    () => this.setState({idCopied: false})
                  );
                });
              }
            }
          >
            <ListItemText primary={'ID: ' + offer.id} secondary="Нажмите, чтобы скопировать"/>
            <Tooltip placement="left" open={idCopied} title="Скопировано">
              <icons.Assignment style={{color: colors.grey[600]}}/>
            </Tooltip>
          </ListItem>
        ) : null}
        {archiveOfferDialogOpened ? (
          <ArchiveOfferDialog
            offer={offer}
            onSubmit={() => this.setState({archiveOfferDialogOpened: false})}
            onClose={() => this.setState({archiveOfferDialogOpened: false})}
            open={Boolean(archiveOfferDialogOpened)}
            initialValues={{
              source: offer.source ? offer.source : null,
              status: 'archived',
              archiveReason: offer.archiveReason ? offer.archiveReason : null,
            }}
            offerSourceLabels={offerSourceLabels}
            offerArchiveReasonLabels={offerArchiveReasonLabels}
          />
        ) : null}
      </div>
    );
  }
}

OfferDetails = connect(
  state => ({
    formatPrice: createPriceFormatter(state.root.classification.priceTypes, state.root.classification.currencyCodes),
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    offerExportLabels: mapLabels(state.root.classification.offerExports, 'value', 'label'),
    offerStatuses: state.root.classification.offerStatuses,
    offerStatusLabels: mapLabels(state.root.classification.offerStatuses, 'value', 'label'),
    offerSourceLabels: mapLabels(state.root.classification.offerSources, 'value', 'label'),
    offerArchiveReasonLabels: mapLabels(state.root.classification.offerArchiveReasons, 'value', 'label'),
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    propertyTypes: state.root.classification.propertyTypes,
    propertyPurposeLabels: mapLabels(state.root.classification.propertyPurposes, 'value', 'label'),
    selfUser: state.root.selfUser,
    avitoAdStatuses: mapLabels(state.root.classification.avitoAdStatuses, 'value', 'label'),
  }),
  {
    openLightbox,
    updateOffer,
    setOfferStatus,
  },
)(OfferDetails);

const styles = theme => ({
  avatar: {
    background: colors.green[300],
  },
  photos: theme.mixins.gutters({
    paddingTop: 8,
    paddingBottom: 8,
  }),
  headerContent: {
    overflow: 'hidden',
  },
  headerTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  externalLinkIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',
  },
  externalLinkListItem: {
    cursor: 'pointer',
    '&:hover $externalLinkIcon': {
      visibility: 'visible',
    },
  },
  errorMessage: {
    color: colors.red[500],
  },
  avitoReportBadge: {
    minWidth: 16,
    height: 16,
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '16px',
    color: 'white',
    fontWeight: 500,
    margin: '0 5px',
    padding: 2,
    '&._errors': {
      backgroundColor: colors.red[500],
    },
    '&._warnings': {
      backgroundColor: colors.yellow[800],
    },
  },
});

export default withRouter(withStyles(styles)(OfferDetails));
