import axios from 'axios';
import {set, without, filter, unionBy} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError, formValueSelector, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import * as icons from '@material-ui/icons';

import {withStyles} from '@material-ui/core/styles';

import Form from './form';
import ContactForm from '../contacts/form/ContactForm';

class PropertyForm extends Component {
  state = {
    contact: null,
    createContact: null,
    assignees: [],
    contactAssignees: [],
  };

  componentDidMount() {
    if (this.props.open && !this.props.initialized) {
      if (this.props.property) {
        const property = this.props.property;
        this.setState({assignees: property.assignees});

        if (property.type.attributes) {
          const attributes = property.type.attributes[property.type.value];
          if (Array.isArray(attributes)) {
            property.type.attributes[property.type.value] = {};
          }
        }

        this.props.initialize({
          property: {
            ...property,
            assignees: property.assignees.map(assignee => assignee.id),
          },
        });
      } else {
        this.props.initialize({
          property: {
            status: 'new',
            address: {},
            type: {},
            additionalTypes: [],
            purposes: [],
            area: {unit: 'm2'},
            photos: [],
            layouts: [],
            assignees: [],
            watermark: false,
            selfAssignee: true,
          },
          contact: {
            attributes: [],
            persons: [],
            status: 'opened',
            open: false,
            showTeam: false,
            blacklist: false,
            assignees: [],
            selfAssignee: true,
          },
        });
      }
    }
  }

  onContactSelect = contact => {
    const {change, selfUser} = this.props;
    change('ownerId', contact.id);
    this.setState({contact});
    if (contact.assignees) {
      const contactAssignees = contact.assignees.filter(
        assignee => assignee.id !== selfUser.id
      );
      const newContactAssignees = unionBy(
        this.state.assignees,
        contactAssignees,
        'id'
      );
      this.setState({assignees: newContactAssignees});
      change(
        'property.assignees',
        newContactAssignees.map(assignee => assignee.id)
      );
    }
  };

  render() {
    const {
      address,
      array,
      change,
      classes,
      handleSubmit,
      initialized,
      open,
      property,
      propertyType,
      offerType,
      contactAttributes,
      contactType,
      contactPersons,
      contactSubject,
      pristine,
      submitting,
      onClose,
      teams,
      blacklist,
      showTeam,
    } = this.props;
    const {contact, createContact, assignees, contactAssignees} = this.state;

    if (!open || !initialized) {
      return null;
    }

    if (createContact) {
      return (
        <Dialog fullScreen open classes={{paper: classes.dialog}}>
          <DialogTitle>Контакт</DialogTitle>
          <Divider />
          <DialogContent>
            <ContactForm
              array={array}
              change={change}
              assignees={contactAssignees}
              selfAssignee={true}
              attributes={contactAttributes}
              contactType={contactType}
              persons={contactPersons}
              subject={contactSubject}
              blacklist={blacklist}
              showTeam={showTeam}
              team={teams[0]}
              onAssigneeSelect={assignee => {
                array.push('contact.assignees', assignee.id);
                this.setState({
                  contactAssignees: [...this.state.contactAssignees, assignee],
                });
              }}
              onAssigneeUnselect={index => {
                array.remove('contact.assignees', index);
                this.setState({
                  contactAssignees: without(
                    this.state.contactAssignees,
                    this.state.contactAssignees[index]
                  ),
                });
              }}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              type="button"
              disabled={submitting}
              onClick={() =>
                this.setState({createContact: null, contact: null})
              }
            >
              Отменить
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={handleSubmit(this.onOwnerSubmit)}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <Dialog fullScreen open={open} classes={{paper: classes.dialog}}>
        <DialogTitle>
          Объект
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Form
            id={property ? property.id : null}
            address={address}
            propertyType={propertyType}
            offerType={offerType}
            change={change}
            array={array}
            assignees={assignees}
            team={teams[0]}
            owner={contact}
            onOwnerCreate={createContact => {
              this.setState({createContact});
              change('contact.phone', createContact.phone);
            }}
            onOwnerUnassign={() => {
              change('ownerId', null);
              this.setState({contact: null});
            }}
            onOwnerSelect={contact => {
              this.onContactSelect(contact);
            }}
            onAssigneeSelect={assignee => {
              array.push('property.assignees', assignee.id);
              this.setState({assignees: [...this.state.assignees, assignee]});
            }}
            onAssigneeUnselect={index => {
              array.remove('property.assignees', index);
              this.setState({
                assignees: without(
                  this.state.assignees,
                  this.state.assignees[index]
                ),
              });
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = ({property, ownerId}) => {
    const {onSubmitted} = this.props;

    return axios
      .post(
        property.id ? `/api/properties/${property.id}` : '/api/properties',
        {
          id: property.id,
          property,
          ownerId,
        }
      )
      .then(resp => {
        property.layouts = resp.data.layouts;
        property.photos = resp.data.photos;
        property.overallVideo = resp.data.overallVideo;
        property.trafficVideo = resp.data.trafficVideo;
        onSubmitted({
          ...property,
          id: resp.data.id,
          assignees: this.state.assignees,
          createdAt: property.id ? property.createdAt : new Date(),
        });
      })
      .catch(error => {
        if (error.response.status === 400) {
          if(error.response.data.error.errors)
            throw new SubmissionError(
              error.response.data.error.errors.reduce(
                (errors, {propertyPath, message}) => {
                  return set(errors, propertyPath, message);
                },
                {}
              )
            );
          else
            alert(error.response.data.error);
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };

  onOwnerSubmit = ({contact}) => {
    const {change, selfUser} = this.props;

    return axios
      .post('/api/contacts', {contact})
      .then(resp => {
        change('ownerId', resp.data.id);
        let contactAssignees = filter(
          resp.data.assignees,
          assignee => assignee.id !== selfUser.id
        );
        const newContactAssignees = unionBy(
          this.state.assignees,
          contactAssignees,
          'id'
        );
        this.setState({
          assignees: newContactAssignees,
          createContact: null,
          contact: {
            ...contact,
            id: resp.data.id,
          },
        });
        change(
          'property.assignees',
          newContactAssignees.map(assignee => assignee.id)
        );
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(
            error.response.data.error.errors.reduce(
              (errors, {propertyPath, message}) => {
                return set(errors, propertyPath, message);
              },
              {}
            )
          );
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

PropertyForm = withStyles(styles)(PropertyForm);

const selector = formValueSelector('property');
PropertyForm = connect(state => ({
  propertyType: selector(state, 'property[type][value]'),
  offerType: selector(state, 'property[offerType]'),
  address: selector(state, 'property[address]'),
  contactType: selector(state, 'contact.type'),
  contactAttributes: selector(state, 'contact.attributes'),
  contactPersons: selector(state, 'contact.persons'),
  contactSubject: selector(state, 'contact.subject'),
  selfUser: state.root.selfUser,
  blacklist: selector(state, 'contact.blacklist'),
  showTeam: selector(state, 'contact.showTeam'),
  teams: state.root.selfUser.teams,
}))(PropertyForm);

export default reduxForm({
  form: 'property',
})(PropertyForm);
