import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {mapLabels} from '../../utils';
import {openLightbox, updateOffer, updatePropertyOffer} from '../../actions';
import OfferDetails from './OfferDetails';
import OfferEdit from './OfferEdit';
import OfferReport from './OfferReport';
import ReportForm from './form/ReportForm';
import PresentationForm from './form/PresentationForm';
import CardForm from './form/CardForm';
import OfferMaterials from './OfferMaterials';
import {downloadOfferPhotoZip} from '../../utils/photoFunc';
import OfferAvitoReport from './OfferAvitoReport';

class OfferDetailsWrapper extends Component {
  state = {
    edit: false,
    openMenu: false,
    formReport: false,
    showMaterials: false,
    showAvitoReport: false,
  };

  getFormComponent() {
    switch(this.state.formReport.component) {
      case 'reportForm':
        return (
          <ReportForm/>
        );
      case 'presentationForm':
        return (
          <PresentationForm/>
        );
      case 'cardForm':
        return (
          <CardForm/>
        );
      default:
        break;
    }
  }

  render() {
    const {
      classes,
      offer,
      offerTypeLabels,
      propertyTypeLabels,
      updateOffer,
      updatePropertyOffer,
    } = this.props;

    const {openMenu, edit, formReport, showMaterials, showAvitoReport} = this.state;

    if (!offer) {
      return null;
    }

    return (
      <Paper elevation={1} square className="column">
        <CardHeader
          action={
            <IconButton
              onClick={e => {
                this.setState({openMenu: {target: e.currentTarget}});
              }}
            >
              <icons.MoreVert/>
            </IconButton>
          }
          classes={{content: classes.headerContent, title: classes.headerTitle}}
          title={propertyTypeLabels[offer.property.type.value] || offer.property.type.value}
          subheader={'№' + offer.no + ' - ' + (offerTypeLabels[offer.type] || offer.type)}
        />
        <Divider/>
        <OfferDetails offer={offer}/>
        {openMenu ? (
          <Menu
            anchorEl={openMenu.target}
            open={Boolean(openMenu)}
            onClick={() => this.setState({openMenu: false})}
          >
            <MenuItem onClick={() => this.setState({edit: true})}>
              Редактировать
            </MenuItem>
            <MenuItem onClick={() => this.setState({formReport: {title: 'Сформировать отчет собственнику', component: 'reportForm', action: '/reports/work.php'}})}>
              Сформировать отчет
            </MenuItem>
            <MenuItem onClick={() => this.setState({formReport: {title: 'Презентация под клиента', component: 'presentationForm', action: '/marketing/custom_presentation/custom_presentation.php'}})}>
              Презентация под клиента
            </MenuItem>
            <MenuItem onClick={() => this.setState({formReport: {title: 'Карточка под клиента', component: 'cardForm', action: '/marketing/custom_card/custom_card.php'}})}>
              Карточка под клиента
            </MenuItem>
            <MenuItem onClick={() => this.setState({showMaterials: true})}>
              Маркетинговые материалы
            </MenuItem>
            <MenuItem onClick={() => downloadOfferPhotoZip(offer.id, propertyTypeLabels[offer.property.type.value], offer.no)}>
              Скачать архив фотографий
            </MenuItem>
            {
              offer.avitoReport ? (
                <MenuItem onClick={() => this.setState({showAvitoReport: true})}>
                  Отчёт Авито
                </MenuItem>
              ) : null
            }
          </Menu>
        ) : null}
        {edit ? (
          <OfferEdit
            open={Boolean(edit)}
            offer={offer}
            onSubmitted={offer => {
              updateOffer(offer);
              if(offer.origin)
                updatePropertyOffer(offer.id, offer.origin, offer.status);
              this.setState({edit: false});
            }}
            onClose={() => this.setState({edit: false})}
          />
        ) : null}
        {formReport ? (
          <OfferReport
            open={Boolean(formReport)}
            data={formReport}
            offer={offer}
            onClose={() => this.setState({formReport: false})}
          >
            {this.getFormComponent()}
          </OfferReport>
        ) : null}
        {showMaterials ? (
          <OfferMaterials
            open={showMaterials}
            offer={offer}
            onClose={() => this.setState({showMaterials: false})}
          />
        ) : null}
        {showAvitoReport ? (
          <OfferAvitoReport
            open={showAvitoReport}
            offer={offer}
            onClose={() => this.setState({showAvitoReport: false})}
          />
        ) : null}
      </Paper>
    );
  }
}

OfferDetailsWrapper = connect(
  state => ({
    offerTypeLabels: mapLabels(state.root.classification.offerTypes, 'value', 'label'),
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
  }),
  {
    openLightbox,
    updateOffer,
    updatePropertyOffer,
  },
)(OfferDetailsWrapper);

const styles = theme => ({
  avatar: {
    background: colors.green[300],
  },
  photos: theme.mixins.gutters({
    paddingTop: 8,
    paddingBottom: 8,
  }),
  headerContent: {
    overflow: 'hidden',
  },
  headerTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  externalLinkIcon: {
    fontSize: 18,
    color: colors.grey[600],
    visibility: 'hidden',
    marginLeft: 'auto',
  },
  externalLinkListItem: {
    cursor: 'pointer',
    '&:hover $externalLinkIcon': {
      visibility: 'visible',
    },
  },
});

export default withRouter(withStyles(styles)(OfferDetailsWrapper));
