import React, {Component} from 'react';
import {connect} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors/index';
import MobilePaginationToolbar from '../../components/MobilePaginationToolbar';
import {
  mapLabels,
  formatDateWithoutTime,
} from '../../utils';
import TablePaginationActions from '../../components/TablePaginationActions';
import TableLink from '../../components/TableLink';

class NetRenterListItems extends Component {
  state = {
    anchorEl: null,
    popoverContent: null,
    openExportMenu: null,
    loading: false,
  };

  areaFormatter(areaFrom, areaTo) {
    if (areaFrom && areaTo) {
      if (areaFrom === areaTo) {
        return areaFrom + ' м²';
      }
      return 'От ' + areaFrom + ' до ' + areaTo + ' м²';
    }

    if (areaFrom && !areaTo) {
      return 'От ' + areaFrom + ' м²';
    }

    if (!areaFrom && areaTo) {
      return 'До ' + areaTo + ' м²';
    }
  }

  render() {
    const {
      classes,
      query,
      deals,
      history,
      totalCount,
      handleChangePage,
      perPage,
      netRenterTypes,
    } = this.props;

    const {anchorEl, popoverContent} = this.state;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  Создан / Изменен
                </TableCell>
                <TableCell>Торговая марка</TableCell>
                <TableCell>Контакт&nbsp;/ Телефон</TableCell>
                <TableCell>Вид деятельности</TableCell>
                <TableCell>Площадь</TableCell>
                <TableCell>Город</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deals.map(deal => {
                return (
                  <TableLink
                    className={classes.tableRow}
                    key={deal.id}
                    href={`/netRenters/${deal.id}`}
                    hover
                    onClick={e => {
                      e.preventDefault();
                      history.push(`/netRenters/${deal.id}`);
                    }}
                  >
                    <TableCell>
                      <Typography variant="caption">
                        {formatDateWithoutTime(deal.createdAt)}
                        &nbsp; / &nbsp;
                        {formatDateWithoutTime(deal.updatedAt)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {deal.trademark}
                    </TableCell>
                    <TableCell>
                      {deal.contact ? deal.contact.name + '\n' + deal.contact.phone : null}
                    </TableCell>
                    <TableCell>
                      {netRenterTypes[deal.type] || deal.type}
                    </TableCell>
                    <TableCell>
                      {this.areaFormatter(deal.areaFrom || null, deal.areaTo || null)}
                    </TableCell>
                    <TableCell>
                      {deal.city ? deal.city.name : ''}
                    </TableCell>
                  </TableLink>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.pagination}>
            <TablePagination
              rowsPerPage={perPage}
              component={'div'}
              rowsPerPageOptions={[perPage]}
              count={totalCount}
              page={query.offset ? Math.floor(query.offset / perPage) : 0}
              onChangePage={handleChangePage}
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} из ${count}`
              }
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Hidden>
        <Hidden smUp>
          {deals.map((deal, i) => {
            return (<Paper
              key={i}
              elevation={0}
              onClick={() => history.push(`/netRenters/${deal.id}`)}
              square
            >
              <CardContent style={{position: 'relative'}}>
                <div
                  className={classes.status}
                />
                <Typography >
                  {deal.city ? deal.city.name : ''} <br/>
                  {deal.contact ? deal.contact.name + ' / ' + deal.contact.phone : null}
                </Typography>
                <Typography variant="subtitle1">
                  {deal.trademark}
                </Typography>
                <Typography variant="caption">
                  {netRenterTypes[deal.type] || deal.type} <br/>
                  {this.areaFormatter(deal.areaFrom || null, deal.areaTo || null)}
                </Typography>
              </CardContent>
              <Divider/>
            </Paper>);
          })}
          <MobilePaginationToolbar
            totalCount={totalCount}
            page={query.offset ? Math.ceil(query.offset / 30) : 0}
            handleOnChangePage={handleChangePage}
          />
        </Hidden>
        <Popover
          classes={{paper: classes.requirementsPopover}}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handlePopoverClose}
        >
          <Typography>{popoverContent}</Typography>
        </Popover>
      </React.Fragment>
    );
  }

  handlePopoverOpen = (e, text) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({anchorEl: e.currentTarget.parentNode, popoverContent: text});
  };

  handlePopoverClose = () => {
    this.setState({anchorEl: null, popoverContent: null});
  };
}

const styles = theme => ({
  table: {
    background: 'white',
  },
  tableRow: {
    display:'table-row',
    color: 'inherit',
    height: 48,
    outline: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    cursor: 'default',
    '&:hover': {
      backgroundColor:'rgba(0,0,0,0.07)',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 56,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: 'white',
    marginBottom: 56 + 48,
  },
  action: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 8,
    },
    top: 0,
  },
  sortableCell: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  popoverIcon: {
    width: 32,
    height: 16,
    display: 'inline',
    cursor: 'pointer',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
  },
  requirementsPopover: {
    maxWidth: 300,
    padding: 8,
    whiteSpace: 'pre-line',
  },
  requirementsCell: {
    whiteSpace: 'normal',
    minWidth: 200,
  },
  requirementsWrapper: {
    overflow: 'hidden',
    maxHeight: 48,
    lineClamp: 3,
    fontSize: '0.75rem',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  status: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 6,
    height: '100%',
  },
  malformedIcon: {
    color: colors.orange[400],
    width: 16,
    height: 16,
    marginRight: 8,
    float: 'left',
    verticalAlign: 'middle',
  },
  malformedIconHard: {
    color: colors.red[400],
    width: 16,
    height: 16,
    marginRight: 8,
    float: 'left',
    verticalAlign: 'middle',
  },
  mobilePagination: {
    marginRight: 16,
  },
  rightIcon: {
    marginRight: -12,
  },
});

export default connect(
  state => ({
    selfUser: state.root.selfUser,
    netRenterTypes: mapLabels(state.root.classification.netRenterTypes, 'value', 'label'),
  })
)(withStyles(styles)(NetRenterListItems));
