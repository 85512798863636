import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';

import {mapLabels, formatDate} from '../../../utils';
import TableLink from '../../../components/TableLink';
import axios from 'axios';

class DealOfferTable extends Component {
  constructor(props) {
    super(props);

    if (typeof props.statistics.items === 'undefined' || props.statistics.items === null) {
      props.statistics.items = [];
    }

    this.state = {
      statistics: props.statistics,
    };
  }

  onIgnoreExportsChangeResult = (idx, res) => {
    if (res && res.data && res.data.ignoreExports) {
      this.setState(state => {
        state.statistics.items[idx].ignoreExports = res.data.ignoreExports;
        state.statistics.items[idx].exportAvitoTimer = res.data.exportAvitoTimer;
        return {statistics: state.statistics};
      });
    }
  };

  handleIgnoreExportsChange = (idx, source, sourceIndex) => {
    if (!this.state.statistics.items[idx]) return;

    let item = JSON.parse(JSON.stringify(this.state.statistics.items[idx]));

    if (sourceIndex > -1) {
      item.ignoreExports.splice(sourceIndex, 1);
    } else {
      item.ignoreExports.push(source);
    }

    const yIdx = item.ignoreExports.indexOf('yandex_realty');
    if (yIdx > -1) {
      item.ignoreExports[yIdx] = 'yandex';
    }

    this.send({
      id: item.id,
      ignoreExports: item.ignoreExports,
    }).then(res => this.onIgnoreExportsChangeResult(idx, res));
  };

  handleAvitoTimerReset = idx => {
    if (!this.state.statistics.items[idx]) return;

    let item = JSON.parse(JSON.stringify(this.state.statistics.items[idx]));

    this.send({
      id: item.id,
      ignoreExports: item.ignoreExports,
      avitoTimerReset: true,
    }).then(res => this.onIgnoreExportsChangeResult(idx, res));
  };

  send = data => {
    return axios.post('/api/v1/setIgnoreExportsOfferAction', data)
      .catch(error => {
        if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };

  render() {
    const {
      classes,
      statistics,
      title,
      handleClickOpen,
      dealSourceLabels,
      titleClass,
      getHref,
      offerExports,
      // selfUser,
    } = this.props;

    let sources = {}, items = [];

    this.state.statistics.items.map(item => {
      let copy = {...item};
      copy.counts = {};
      item.counts.map(countItem => {
        if (sources[countItem.source]) {
          sources[countItem.source] += countItem.count;
        } else {
          sources[countItem.source] = countItem.count;
        }
        copy.counts[countItem.source] = countItem.count;
        return countItem;
      });

      items.push(copy);
      return item;
    });

    offerExports['avito'] = true;
    offerExports['yandex_realty'] = true;

    const _sourceList = Object.keys(sources);
    let sourceList = [];
    _sourceList.map(source => {
      if (offerExports[source]) {
        sourceList.push(source);
      }
    });
    _sourceList.map(source => {
      if (!offerExports[source]) {
        sourceList.push(source);
      }
    });

    return (
      <React.Fragment>
        <Typography
          variant="subtitle1"
          className={titleClass}
        >
          {title}
        </Typography>
        <Paper
          className={classes.root}
          style={{
            overflowX: 'auto',
            marginBottom: 104,
          }}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow classes={{root: classes.tableRow}}>
                <TableCell className={classes.addressColumn}>Адрес</TableCell>
                <TableCell>Всего</TableCell>
                {sourceList.map((source, i) => (
                  <TableCell
                    key={'header' + i}
                    className={classes.tableCellCenter}
                  >{dealSourceLabels[source]}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, i) => (
                <TableLink
                  key={i}
                  href={getHref(item.id)}
                  className={classes.tableRow}
                  hover
                  onClick={() => handleClickOpen(item.id)}
                >
                  <TableCell
                    className={classes.addressColumn}
                    title={item.address}
                  >{item.address}</TableCell>
                  <TableCell className={classes.tableCellCenter}>{item.total}</TableCell>
                  {sourceList.map((source, j) => {
                    const sourceIndex = item.ignoreExports.indexOf(source === 'yandex_realty' ? 'yandex' : source);
                    const isExportWaiting = source === 'avito' && item.exportAvitoTimer === 'true';
                    const supportExport = offerExports[source];
                    let exportLabel = 'Выгружается';
                    if (source === 'avito' && item.exportAvitoTimer && item.exportAvitoTimer !== 'true') {
                      exportLabel += ' до ' + formatDate(item.exportAvitoTimer);
                    }
                    return (
                      <TableCell
                        key={i + '' + j}
                        className={classes.sourceExportedLabelWrapper}
                        title={
                          `${supportExport
                            ? (dealSourceLabels[source] + ' - ' + (sourceIndex > -1
                              ? (isExportWaiting ? 'На паузе' : 'Не выгружается') : exportLabel))
                            : ''
                          }`
                        }
                        onClick={e => {
                          if (!supportExport) return;
                          e.stopPropagation();
                          if (isExportWaiting) {
                            this.handleAvitoTimerReset(i);
                            return;
                          }
                          this.handleIgnoreExportsChange(i, source, sourceIndex);
                        }}
                      >
                        <span className={classes.sourceExportedNum}>
                          {item.counts[source] ? item.counts[source] : 0}
                        </span>
                        {supportExport ?
                          <div
                            className={
                              classes.sourceExportedLabel + ` ${sourceIndex > -1
                                ? (isExportWaiting ? 'pausing' : 'disabled') : ''}`
                            }
                          /> : null
                        }
                      </TableCell>
                    );
                  })}
                </TableLink>
              ))}
              <TableRow>
                <TableCell>Всего:</TableCell>
                <TableCell className={classes.tableCellCenter}>{statistics.total}</TableCell>
                {sourceList.map((source, i) => (
                  <TableCell
                    key={'total' + i}
                    className={classes.tableCellCenter}
                  >{sources[source] ? sources[source] : 0}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  table: {
    background: 'white',
  },
  root: {
    margin: '0 20px',
  },
  addressColumn: {
    maxWidth: 200,
  },
  tableRow: {
    height: 37,
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  sourceExportedLabelWrapper: {
    color: '#607d8b',
    marginBottom: 10,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& > div': {
        backgroundColor: '#1175f6',
      },
    },
  },
  sourceExportedNum: {
    lineHeight: 2,
  },
  sourceExportedLabel: {
    width: 8,
    height: 8,
    backgroundColor: '#607d8b',
    borderRadius: '50%',
    marginLeft: 3,
    display: 'inline-block',
    '&.disabled': {
      backgroundColor: '#ccc',
    },
    '&.pausing': {
      backgroundColor: '#f9a825',
    },
  },
});

export default connect(
  state => ({
    offerExports: mapLabels(state.root.classification.offerExports, 'value', 'label'),
    dealSourceLabels: mapLabels(state.root.classification.dealSources, 'value', 'label'),
    selfUser: state.root.selfUser,
  })
)(withStyles(styles)(DealOfferTable));
