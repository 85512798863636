import * as axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import * as icons from '@material-ui/icons';
import RequestDetailsWrapper from './RequestDetailsWrapper';
import RequestEdit from './RequestEdit';
import Assignees from './relations/Assignees';
import Contact from './relations/Contact';
import Offers from './relations/Offers';
import Row from '../../components/Row';
import Column from '../../components/Column';
import EditableComment from '../../components/EditableComment';
import {queryRequestDetails} from '../../queries';
import {
  capitalizeFirstLetter,
  createAreaFormatter, isSingleDealMenuSection,
  mapLabels, needUpdatePageTitleByDealMenuSection,
  showResponseError,
} from '../../utils';
import ActivityEventsList from '../../components/ActivityEventsList';
import {hasRole} from '../../utils/roleFunc';
import * as userRoles from '../../constants/userRoles';
import ContactDialog from '../contacts/ContactDialog';
import OfferDialog from '../offer/OfferDialog';
import AssigneeDialog from '../contacts/AssigneeDialog';
import {setTitle} from '../../actions';
import RequestFeedbackStatus from './RequestFeedbackStatus';
import EditableField from '../../components/EditableField';
import RequestFeedbackChange from './form/RequestFeedbackChange';
import Team from './relations/Team';

class RequestView extends Component {
  state = {
    openAdd: null,
    openEdit: false,
    deal: null,
    openContactDialog: false,
    openOfferDialog: false,
    openAssigneeDialog: false,
    openDealOfferDialog: false,
    openSelection: false,
    dealOfferLoading: false,
    offerLoading: false,
    dealOfferError: false,
    offerError: false,
    nextStatus: null,
    changeStatusComment: false,
    offerPage: 1,
    offerTotalCount: 0,
    dealOfferPage: 1,
    dealOfferTotalCount: 0,
  };

  perPage = 5;

  handleDialogClose = name => {
    this.setState({[name]: false});
  };

  handleDialogOpen = (value, name) => {
    this.setState({[name]: value});
  };

  componentDidMount() {
    this.props.setTitle('Заявки beta');

    if (this.props.selfUser && this.props.selfUser.id) {
      if (isSingleDealMenuSection(this.props.selfUser)) {
        this.props.setTitle('Заявки');
      }
    }

    this.load();
  }

  componentDidUpdate(prevProps) {
    if (needUpdatePageTitleByDealMenuSection(prevProps, this.props)) {
      if (isSingleDealMenuSection(this.props.selfUser)) {
        this.props.setTitle('Заявки');
      }
    }
  }

  componentWillUnmount() {
  }

  load() {
    const {match} = this.props;
    queryRequestDetails(match.params.requestId)
      .then(resp => {
        if (resp.status === 200 && !resp.data.errors) {
          this.setState({
            deal: resp.data.data.request,
          });
          return;
        }
        alert('Произошла ошибка!');
      })
      .catch(() => {
        alert('Произошла ошибка!');
      });
  }

  getDealOffers = () => {
  };

  saveComment = (id, nextComment, prevComment) => {
    this.setState({
      deal: {
        ...this.state.deal,
        comment: nextComment,
      },
    });

    return axios.post('/api/v1/setRequestComment', {id, comment: nextComment})
      .then(() => this.activityEventsList.updateActivity())
      .catch(() => {
        this.setState({
          deal: {
            ...this.state.deal,
            comment: prevComment,
          },
        });
        showResponseError();
      });
  };

  saveAction = (id, next, prev) => {
    this.setState({
      deal: {
        ...this.state.deal,
        action: next,
      },
    });

    return axios.post('/api/v1/setRequestAction', {id, action: next})
      .then(() => this.activityEventsList.updateActivity())
      .catch(() => {
        this.setState({
          deal: {
            ...this.state.deal,
            action: prev,
          },
        });
        showResponseError();
      });
  };

  saveResult = (id, next, prev) => {
    this.setState({
      deal: {
        ...this.state.deal,
        result: next,
      },
    });

    return axios.post('/api/v1/setRequestResult', {id, result: next})
      .then(() => this.activityEventsList.updateActivity())
      .catch(() => {
        this.setState({
          deal: {
            ...this.state.deal,
            result: prev,
          },
        });
        showResponseError();
      });
  };

  saveRequirements = (id, newReq, oldReq) => {
    this.setState({
      deal: {
        ...this.state.deal,
        requirements: newReq,
      },
    });

    return axios.post('/api/v1/setRequestRequirements', {id, requirements: newReq})
      .then(() => this.activityEventsList.updateActivity())
      .catch(() => {
        this.setState({
          deal: {
            ...this.state.deal,
            requirements: oldReq,
          },
        });
        showResponseError();
      });
  };

  onSubmitFeedbackClose = ({feedbackYes, feedbackWait, waitingDeadline, feedbackNo, feedbackViewed}) => {
    this.activityEventsList && this.activityEventsList.updateActivity();
    this.setState({
      deal: {
        ...this.state.deal,
        feedbackYes,
        feedbackWait,
        waitingDeadline,
        feedbackNo,
        feedbackViewed,
      },
    });
    this.handleDialogClose('openFeedbackDialog');
  };

  render() {
    const {
      deal,
      openEdit,
      openAssigneeDialog,
      openOfferDialog,
      openContactDialog,
      openFeedbackDialog,
      offerLoading,
      offerError,
    } = this.state;

    const {
      history,
      match,
      selfUser,
      requestFeedbackLabels,
    } = this.props;

    if (!deal) {
      return null;
    }

    const feedbackStatus = [];
    ['yes', 'wait', 'no', 'viewed'].forEach(status => {
      feedbackStatus.push(
        <RequestFeedbackStatus
          key={status}
          status={status}
          value={deal['feedback' + capitalizeFirstLetter(status)] === true}
          waitingDeadline={deal.waitingDeadline}
          labels={requestFeedbackLabels}
          onEditStatus={e => this.handleDialogOpen(e, 'openFeedbackDialog')}
        />
      );
    });

    return (
      <React.Fragment>
        <Row>
          <Column label="Информация" icon={<icons.InfoOutlined/>} key="information">
            <RequestDetailsWrapper
              data={deal}
              onEdit={() => this.setState({openEdit: true})}
              onActionChanged={action => this.saveAction(deal.id, action, deal.action)}
              onResultChanged={result => this.saveResult(deal.id, result, deal.result)}
            />
          </Column>
          <Column label="История" icon={<icons.History/>} key="activity">
            <div className="column" style={{background: colors.grey[200], zIndex: 0}}>
              <div className="content" style={{padding: 16}}>
                {feedbackStatus}
                <EditableField
                  field={deal.requirements}
                  onSave={requirements => this.saveRequirements(deal.id, requirements, deal.requirements)}
                  name="Дополнительные пожелания"
                  color={colors.blue[100]}
                />
                <EditableComment
                  comment={deal.comment}
                  onSave={comment => this.saveComment(deal.id, comment, deal.comment)}
                />
                {hasRole(selfUser.role, userRoles.ADMIN) || selfUser.team === deal.team.id ?
                  <div style={{marginTop: 20}}>
                    <ActivityEventsList
                      entityId={match.params.requestId}
                      onRef={ref => (this.activityEventsList = ref)}
                      aggregateType="request"
                    />
                  </div>
                  : null}
              </div>
            </div>
          </Column>
          <Column label="Связи" icon={<icons.Share/>} key="relationships">
            <Paper elevation={1} square className="column">
              <div className="content">
                <Team show={hasRole(selfUser.role, userRoles.ADMIN)} team={deal.team} />
                <Assignees
                  deal={deal}
                  handleDialogOpen={e => this.handleDialogOpen(e, 'openAssigneeDialog')}
                  updateActivity={() => this.activityEventsList.updateActivity()}
                />
                <Divider/>
                <Contact data={deal}/>
                <Divider/>
                <Offers
                  loading={offerLoading}
                  reload={() => this.load()}
                  error={offerError}
                  data={deal}
                  updateActivity={() => this.activityEventsList.updateActivity()}
                />
              </div>
            </Paper>
          </Column>
        </Row>
        {openEdit ? (
          <RequestEdit
            open={openEdit}
            id={deal.id}
            data={deal}
            onSubmitted={() => {
              this.load();
              this.activityEventsList && this.activityEventsList.updateActivity();
              this.setState({openEdit: false});
            }}
            onClose={() => this.setState({openEdit: false})}
          />
        ) : null}
        {openContactDialog ? (
          <ContactDialog
            history={history}
            contact={openContactDialog}
            open={Boolean(openContactDialog)}
            handleClose={() => this.handleDialogClose('openContactDialog')}
          />
        ) : null}
        {openOfferDialog ? (
          <OfferDialog
            history={history}
            offer={openOfferDialog}
            open={Boolean(openOfferDialog)}
            handleClose={() => this.handleDialogClose('openOfferDialog')}
          />
        ) : null}
        {openAssigneeDialog ? (
          <AssigneeDialog
            history={history}
            member={openAssigneeDialog}
            open={Boolean(openAssigneeDialog)}
            handleClose={() => this.handleDialogClose('openAssigneeDialog')}
          />
        ) : null}
        {openFeedbackDialog ? (
          <RequestFeedbackChange
            open={openFeedbackDialog}
            id={deal.id}
            data={deal}
            onSubmit={this.onSubmitFeedbackClose}
            onClose={() => this.handleDialogClose('openFeedbackDialog')}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = () => ({});

export default connect(
  state => ({
    formatArea: createAreaFormatter(state.root.classification.areaUnits),
    dealClosesStatuses: state.root.classification.dealClosesStatuses,
    propertyTypeLabels: mapLabels(state.root.classification.propertyTypes, 'value', 'label'),
    selfUser: state.root.selfUser,
    selections: state.root.selection.selections,
    requestFeedbackLabels: mapLabels(state.root.classification.requestFeedback, 'value', 'label'),
  }),
  {
    setTitle,
  }
)(withStyles(styles)(RequestView));
