import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import {setTitle} from '../../actions';
import MemberList from './MemberList';
import {hasRole} from '../../utils/roleFunc';
import * as userRoles from '../../constants/userRoles';

class Team extends Component {
  componentDidMount() {
    this.props.setTitle('Команда');
  }

  render() {
    const {match, selfUser} = this.props;

    return (
      <Switch>
        <Route
          path={`${match.path}/members`} exact component={({match}) => (
            <MemberList
              teamId={match.params.teamId}
              showAuth={hasRole(selfUser.role, userRoles.MANAGER) || hasRole(selfUser.role, userRoles.ADMIN)}
            />
          )}/>
      </Switch>
    );
  }
}

export default connect(state => ({
  selfUser: state.root.selfUser,
}), {setTitle})(Team);
