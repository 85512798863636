import React, {Component} from 'react';
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import {TextField as MuiTextField} from 'redux-form-material-ui';
import {_onBlur, compare, getListItems, isSubstring} from '../../utils/filter';
import TextField from './TextField';

class SuggestInput extends Component {
  state = {
    list: [],
    selected: false,
    isOpen: false,
    value: '',
    itemValue: 'id',
    itemLabel: 'name',
  };

  onBlur = _onBlur.bind(this);
  getListItems = getListItems.bind(this);

  componentDidMount() {
    const {initializeValue, items, useObject} = this.props;
    const {itemValue, itemLabel} = this.state;

    if (initializeValue) {
      const value = useObject ? initializeValue[itemLabel] : initializeValue;
      let item = items.find(item => compare(item[itemValue], value));
      this.setState({value: item ? item[itemLabel] : null});
    }
    if (items) {
      this.setState({list: items});
    }
  }

  onClickItem = value => {
    const {change, input: {name}} = this.props;
    const {itemValue, itemLabel} = this.state;
    change(name, value[itemValue]);
    this.setState({selected: true, value: value[itemLabel].trim()});
  };

  onFocus = () => {
    const {items} = this.props;
    const {value, itemLabel} = this.state;

    if (value) {
      this.setState({
        isOpen: true,
        list: items.filter(item => isSubstring(item[itemLabel], value)),
      });
    } else {
      this.setState({
        isOpen: true,
        list: items,
      });
    }
  };

  onChange = e => {
    const {change, input: {name}, items} = this.props;
    const {itemLabel} = this.state;
    const {value} = e.target;

    change(name, null);
    this.setState({value});
    if (value === '') {
      let list = items;
      this.setState({list, selected: false, isOpen: true});
    } else {
      let list = items.filter(item => isSubstring(item[itemLabel], value));
      let item = list.find(item => compare(item[itemLabel], value));
      this.setState({list, selected: false, isOpen: true},
        () => {
          item && this.onClickItem(item);
        });
    }
  };

  render() {
    const {classes, input: {name}, label, placeholder, required, meta} = this.props;
    const {list, selected, isOpen, value} = this.state;

    return (
      <React.Fragment>
        <Field
          name={name}
          required
          component={TextField}
          fullWidth
          style={{display: 'none'}}
        />
        <div className={classes.MenuItemWrapper}>
          <MuiTextField
            placeholder={placeholder}
            fullWidth
            onBlur={() => this.onBlur('isOpen')}
            label={label}
            required={required}
            meta={meta}
            margin="none"
            autoComplete="off"
            value={value}
            onFocus={e => {
              this.onFocus(e);
            }}
            onChange={this.onChange}
          />
          {list.length > 0 && !selected && isOpen
            ? <div className={classes.MenuItemContainer}>
              {list.map((value, index) => (
                <MenuItem
                  onClick={() => {
                    this.onClickItem(value);
                  }}
                  key={index}
                >
                  {value.name}
                </MenuItem>
              ))}</div>
            : null}
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  MenuItemContainer: {
    zIndex: 1,
    position: 'absolute',
    width:'100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    maxHeight: 200,
    overflowY: 'auto',
  },
  MenuItemWrapper: {
    position: 'relative',
  },
};

export default connect(() => {}, {})(withStyles(styles)(SuggestInput));
