import React, {Component} from 'react';
import {
  BrowserRouter,
  HashRouter,
  Route,
  withRouter,
  Redirect,
} from 'react-router-dom';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import DateFnsUtils from '@date-io/date-fns';
import classNames from 'classnames';
import locale from 'date-fns/locale/ru';

import {
  authenticate,
  loadPropertyPurposedClassification,
  loadPropertyTypesClassification,
  loadSelfUser,
  loadReferences,
  getUserSettings,
  logout,
  setVersion,
  removeScreen,
} from './actions';
import withAuthentication from './withAuthentication';
import AppBar from './components/AppBar';
import AppMenu from './components/AppMenu';
import ExternalLink from './containers/ExternalLink';
import Lightbox from './containers/Lightbox';
import Team from './containers/team';
import TeamEdit from './containers/team/TeamEdit';
import Contacts from './containers/contacts';
import PressRelease from './containers/pressRelease';
import PublicDeals from './containers/publicDeals';
import Contract from './containers/contract';
import Reference from './containers/reference';
import Dashboard from './containers/dashboard';
import Deals from './containers/deals';
import Requests from './containers/requests';
import NetRenters from './containers/netRenters';
import DealOffers from './containers/dealOffers';
import Offer from './containers/offer';
import Property from './containers/property';
import TaskApp from './is/tasks/containers/TaskApp';
import TaskView from './is/tasks/containers/TaskView';
import FavoritesApp from './is/favorites/containers/FavoritesApp';
import AnalyticsApp from './is/analytics';
import PersonApp from './is/person';
import Favicon from 'react-favicon';
import ChangePasswordView from './is/auth/ChangePasswordView';

const createTheme = () => {
  const theme = createMuiTheme({
    palette: {
      primary: colors.blueGrey,
      secondary: colors.deepOrange,
    },
    mixins: {
      toolbar: {
        minHeight: 56,
      },
    },
    typography: {
      useNextVariants: true,
    },
  });
  theme.overrides = {
    MuiButton: {
      /*dense: {
        padding: '7px 16px',
      },*/
      fab: {
        position: 'absolute',
        right: 16,
        bottom: 16,
        [theme.breakpoints.up('sm')]: {
          right: 24,
          bottom: 24,
        },
      },
    },
    MuiIconButton: {
      /*root: {
        width: 40,
        height: 40,
      },*/
    },
    MuiListItem: {
      gutters: theme.mixins.gutters({}),
    },
    MuiListItemSecondaryAction: {
      root: {
        right: theme.spacing.unit / 2,
        [theme.breakpoints.up('sm')]: {
          right: theme.spacing.unit * 1.5,
        },
      },
    },
    MuiListSubheader: {
      root: theme.mixins.gutters({}),
    },
    MuiCardContent: {
      root: theme.mixins.gutters({}),
    },
    MuiCardHeader: {
      root: theme.mixins.gutters({}),
      /*action: {
        marginRight: -8,
      },*/
    },
    MuiTableCell: {
      root: {
        paddingRight: `${theme.spacing.unit * 1.5}px`,
        paddingLeft: `${theme.spacing.unit * 1.5}px`,
        '&:first-child': {
          paddingLeft: `${theme.spacing.unit * 3}px`,
        },
      },
    },
    MuiTableSortLabel: {
      iconDirectionDesc: {
        transform: 'rotate(180deg)',
      },
      iconDirectionAsc: {
        transform: 'rotate(0deg)',
      },
    },
  };

  return theme;
};

class App extends Component {
  state = {
    addTeam: false,
    editTeam: false,
    openMenu: false,
  };

  theme = createTheme();

  constructor(props) {
    super(props);

    this.theme.overrides.MuiCardContent = {
      root: this.theme.mixins.gutters({}),
    };
  }

  componentDidMount() {
    // this.props.loadPropertyPurposedClassification();
    // this.props.loadPropertyTypesClassification();
    this.props.loadSelfUser();
    this.props.loadReferences();
    // this.props.loadSelfUserTeams();
    this.props.getUserSettings();
  }

  componentWillReceiveProps(nextProps) {
    const {title} = nextProps;

    document.title = `${title} — CRENOW.IS`;
  }

  render() {
    const {classes, logout, removeScreen, selfUser, title, favicon, version} = this.props;
    const {addTeam, editTeam, openMenu} = this.state;

    return (
      <React.Fragment>
        <BrowserRouter>
          <History removeScreen={removeScreen} capacity={20}>
            <MuiThemeProvider theme={this.theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <Favicon url={favicon}/>
                <div className={classNames(classes.root, 'App')}>
                  <AppBar
                    logout={logout}
                    version={version}
                    onMenuClick={() => this.setState({openMenu: true})}
                    selfUser={selfUser}
                    title={title}
                  />
                  <Route path="/news" component={Dashboard} />
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/tasks" />}
                  />
                  <Route path="/favorites" component={FavoritesApp} />
                  <Route path="/analytics" component={AnalyticsApp} />
                  <Route path="/properties" component={Property} />
                  <Route path="/offers" component={Offer} />
                  <Route path="/deals" component={Deals} />
                  <Route path="/requests" component={Requests} />
                  <Route path="/netRenters" component={NetRenters} />
                  <Route path="/members" component={PersonApp} />
                  <Route path="/dealOffers" component={DealOffers} />
                  <Route path="/contacts" component={Contacts} />
                  <Route path="/tasks" component={TaskApp} />
                  <Route path="/contracts" component={Contract} />
                  <Route path="/reference" component={Reference} />
                  <Route path="/teams/:teamId" component={Team} />
                  <Route path="/externalOffers/:id" component={ExternalLink} />
                  <Route path="/pressRelease" component={PressRelease} />
                  <Route path="/publicDeals" component={PublicDeals} />
                  <Route path="/changePassword" component={ChangePasswordView} />
                  <AppMenu
                    onClose={() => this.setState({openMenu: false})}
                    open={openMenu}
                    version={version}
                    user={selfUser}
                    onTeamEdit={teamId => this.setState({editTeam: teamId})}
                    onTeamAdd={() => this.setState({addTeam: true})}
                  />
                  <Lightbox />
                  {editTeam ? (
                    <TeamEdit
                      open={Boolean(editTeam)}
                      onClose={() => this.setState({editTeam: false})}
                      onSubmitted={() => this.setState({editTeam: false})}
                      teamId={editTeam}
                    />
                  ) : null}
                  {addTeam ? (
                    <TeamEdit
                      open={Boolean(addTeam)}
                      onClose={() => this.setState({addTeam: false})}
                      onSubmitted={() => this.setState({addTeam: false})}
                    />
                  ) : null}
                </div>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </History>
        </BrowserRouter>
        <HashRouter>
          <MuiThemeProvider theme={this.theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
              <Route path="/tasks/:id" exact component={TaskView} />
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </HashRouter>
      </React.Fragment>
    );
  }
}

class History extends React.Component {
  keys = [];
  previousKey = null;

  componentWillReceiveProps(nextProps) {
    const key = nextProps.location.key;
    const capacity = nextProps.capacity || 10;

    if (key !== this.previousKey && this.keys.indexOf(key) < 0) {
      const lastKey = this.keys[this.keys.length - 1];

      if (this.previousKey !== lastKey) {
        const previousPos = this.keys.indexOf(this.previousKey);

        for (let i = previousPos + 1; i < this.keys.length; i++) {
          nextProps.removeScreen(this.keys[i]);
        }

        this.keys = this.keys.slice(0, previousPos + 1);
      }

      this.keys.push(key);

      while (this.keys.length > capacity) {
        nextProps.removeScreen(this.keys.shift());
      }
    }

    this.previousKey = key;
  }

  render() {
    return this.props.children;
  }
}

History = withRouter(History);

const styles = theme => ({
  tableScroll: {
    overflowX: 'auto',
  },
  root: {
    background: theme.palette.background.contentFrame,
  },
});

export default connect(
  ({root}) => ({
    title: root.app.title,
    favicon: root.app.favicon,
    version: root.app.version,
    authenticated: root.auth.authenticated,
    selfUser: root.selfUser,
  }),
  {
    authenticate,
    loadPropertyTypesClassification,
    loadPropertyPurposedClassification,
    loadReferences,
    loadSelfUser,
    getUserSettings,
    logout,
    setVersion,
    removeScreen,
  }
)(withAuthentication()(withStyles(styles)(App)));
