import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {Select} from 'redux-form-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '../../../components/Input/TextField';
import Variant from '../../../components/Input/Variant';
import DatePicker from '../../../components/Input/DatePicker';
import {getLabelsForValue, numberWithSpaces} from '../../../utils';
import TeamSelect from '../../team/TeamSelect';
import {hasRole} from '../../../utils/roleFunc';
import * as userRoles from '../../../constants/userRoles';

class FilterDeals extends Component {
  render() {
    const {
      handleSubmit,
      submitting,
      offerTypes,
      onClose,
      onSubmit,
      open,
      pristine,
      teamMembers,
      dealSources,
      activities,
      activitiesDetails,
      requestActions,
      requestResults,
      requestFeedback,
      selfUser,
    } = this.props;

    return (
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Настройки фильтра</DialogTitle>
        <Divider/>
        <DialogContent>
          <Field
            name="searchQuery"
            label="Поисковый запрос"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Field
            name="phoneQuery"
            label="Номер телефона"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Field
            name="feedback"
            label="Обратная связь"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(requestFeedback, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {requestFeedback.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <FormControl fullWidth margin="normal">
            <InputLabel>Вид сделки</InputLabel>
            <Field name="type" component={Select}>
              <MenuItem value=""/>
              {offerTypes.map(({value, label}, i) => (
                <MenuItem value={value} key={i}>{label}</MenuItem>
              ))}
            </Field>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Источник</InputLabel>
            <Field name="source" component={Select}>
              <MenuItem value=""/>
              {dealSources.map(({value, label}, i) => (
                <MenuItem value={value} key={i}>{label}</MenuItem>
              ))}
            </Field>
          </FormControl>
          <Field
            name="activities"
            label="Сферы деятельности"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(activities, value, 'id', 'name').join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {activities.map(({name, id}, index) => (
              <Variant key={index} value={id} label={name}/>
            ))}
          </Field>
          <Field
            name="activitiesDetails"
            label="Детали деятельности"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(activitiesDetails, value, 'id', 'name').join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {activitiesDetails.map(({name, id}, index) => (
              <Variant key={index} value={id} label={name}/>
            ))}
          </Field>
          <Field
            name="actions"
            label="Действия"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(requestActions, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {requestActions.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <Field
            name="results"
            label="Результат"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(requestResults, value).join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {requestResults.map(({label, value}, index) => (
              <Variant key={index} value={value} label={label}/>
            ))}
          </Field>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="areaFrom"
                  label="Площадь от"
                  component={TextField}
                  normalize={value => (value ? value.replace(',', '.') : value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="areaTo"
                  label="до (м²)"
                  component={TextField}
                  normalize={value => (value ? value.replace(',', '.') : value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="priceFrom"
                  label="Бюджет от"
                  component={TextField}
                  normalize={value => numberWithSpaces(value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="priceTo"
                  label="до (₽)"
                  component={TextField}
                  normalize={value => numberWithSpaces(value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Field
            name="requirements"
            label="Дополнительные требования"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Field
            name="addressQuery"
            label="Предложенные листинги"
            component={TextField}
            fullWidth
            margin="normal"
          />
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="dateFrom"
                  label="Дата заявки от"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="dateTo"
                  label="до"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="createdAtFrom"
                  label="Создана с"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="createdAtTo"
                  label="по"
                  format={null}
                  component={props => (
                    <DatePicker
                      format="yyyy-MM-dd"
                      clearable
                      {...props}
                    />
                  )}
                  fullWidth
                  margin="none"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Field
            name="assignees"
            label="Ответственные"
            component={TextField}
            select
            SelectProps={{
              multiple: true,
              renderValue: value => getLabelsForValue(teamMembers, value, 'id', 'name').join(', '),
            }}
            format={value => value || []}
            fullWidth
            margin="normal"
          >
            {teamMembers.map(({id, name}, index) => (
              <Variant key={index} value={id} label={name}/>
            ))}
          </Field>
          {
            hasRole(selfUser.role, userRoles.ADMIN) ? (
              <Field
                name="team"
                label="Команда"
                component={TeamSelect}
                fullWidth
                margin="normal"
              />
            ) : null
          }
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            disabled={submitting}
            onClick={onClose}
          >
            Отменить
          </Button>
          <Button
            type="button"
            color="primary"
            disabled={submitting || pristine}
            onClick={handleSubmit(onSubmit)}
          >
            Применить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FilterDeals = connect(
  state => ({
    selfUser: state.root.selfUser,
    dealStatuses: state.root.classification.dealStatuses,
    offerTypes: state.root.classification.offerTypes,
    propertyTypes: state.root.classification.propertyTypes,
    propertyPurposes: state.root.classification.propertyPurposes,
    teamMembers: state.root.selfUser.teamMembers,
    contactRoles: state.root.classification.contactRoles,
    contactTypes: state.root.classification.contactTypes,
    classificationDeal: state.root.classification.classificationDeal,
    activities: state.root.classification.activities,
    activitiesDetails: state.root.classification.activitiesDetails,
    requestActions: state.root.classification.requestActions,
    requestResults: state.root.classification.requestResults,
    requestFeedback: state.root.classification.requestFeedback,
    dealSources: state.root.classification.dealSources,
  })
)(FilterDeals);

export default reduxForm({
  form: 'filter_requests',
  enableReinitialize: true,
})(FilterDeals);
