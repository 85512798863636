import {set, without, clone} from 'lodash';
import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SubmissionError, formValueSelector, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import * as icons from '@material-ui/icons';
import Form from './form';
import ContactForm from '../contacts/form/ContactForm';
import {queryNetRenterDetails} from '../../queries';

class NetRenterEdit extends Component {
  state = {
    contact: null,
    createContact: null,
    contactAssignees: [],
  };

  componentDidMount() {
    const {initialize, id} = this.props;

    if (id) {
      queryNetRenterDetails(id)
        .then(resp => {
          if (resp.status === 200 && !resp.data.errors) {
            let data = resp.data.data.netRenter;
            if(data.logo){
              data.logos = [data.logo];
            }
            initialize({netRenter: data});
            return;
          }
          alert('Произошла ошибка!');
        })
        .catch(() => {
          alert('Произошла ошибка!');
        });
    } else {
      initialize({
        netRenter: {
          contact: this.props.sourceContact
            ? this.props.sourceContact.id
            : undefined,
          city: null,
          type: null,
          logos: [],
        },
        contact: {
          attributes: [],
          persons: [],
          assignees: [],
          selfAssignee: true,
          status: 'opened',
          offerPrice: {value: {currency: 'RUB'}},
          open: false,
          showTeam: false,
          blacklist: false,
        },
      });
    }
  }

  onContactSelect = contact => {
    const {change} = this.props;
    change('netRenter.contact', contact.id);
    this.setState({contact});
  };

  render() {
    const {
      array,
      change,
      classes,
      contactAttributes,
      contactType,
      contactPersons,
      contactSubject,
      handleSubmit,
      id,
      initialized,
      pristine,
      submitting,
      teams,
      onClose,
      sourceContact,
      blacklist,
      showTeam,
      city,
      logos,
    } = this.props;
    const {
      contact,
      createContact,
      contactAssignees,
    } = this.state;

    if (!initialized) {
      return null;
    }

    if (createContact) {
      return (
        <Dialog fullScreen open classes={{paper: classes.dialog}}>
          <DialogTitle>Контакт</DialogTitle>
          <Divider />
          <DialogContent>
            <ContactForm
              array={array}
              change={change}
              assignees={contactAssignees}
              selfAssignee={true}
              attributes={contactAttributes}
              contactType={contactType}
              persons={contactPersons}
              subject={contactSubject}
              blacklist={blacklist}
              showTeam={showTeam}
              team={teams[0]}
              onAssigneeSelect={assignee => {
                array.push('contact.assignees', assignee.id);
                this.setState({
                  contactAssignees: [...this.state.contactAssignees, assignee],
                });
              }}
              onAssigneeUnselect={index => {
                array.remove('contact.assignees', index);
                this.setState({
                  contactAssignees: without(
                    this.state.contactAssignees,
                    this.state.contactAssignees[index]
                  ),
                });
              }}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              type="button"
              disabled={submitting}
              onClick={() =>
                this.setState({createContact: null, contact: null})
              }
            >
              Отменить
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={handleSubmit(this.onContactSubmit)}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <Dialog fullScreen open classes={{paper: classes.dialog}}>
        <DialogTitle>
          Сетевой арендатор
          <IconButton className={classes.closeButton} onClick={() => onClose()}>
            <icons.Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Form
            id={id}
            array={array}
            change={change}
            team={teams[0]}
            city={city}
            logos={logos}
            contact={sourceContact || contact}
            onContactCreate={createContact => {
              this.setState({createContact});
              change('contact.phone', createContact.phone);
            }}
            onContactUnassign={() => {
              change('netRenter.contact', null);
              this.setState({contact: null});
            }}
            onContactSelect={contact => {
              this.onContactSelect(contact);
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button type="button" disabled={submitting} onClick={() => onClose()}>
            Отменить
          </Button>
          <Button
            type="button"
            disabled={pristine || submitting}
            onClick={handleSubmit(this.submit)}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submit = ({netRenter}) => {
    const {onSubmitted, id} = this.props;
    let url = '/api/v1/createNetRenter';

    if (netRenter.logos) {
      netRenter.logo = netRenter.logos[0];
    }

    let input = clone(netRenter);
    if (input.city && input.city.id) {
      input.city = input.city.id;
    }

    if (id) {
      url = '/api/v1/updateNetRenter';
      input.contact = 'none';
    }

    return axios
      .post(url, {id, ...input})
      .then(resp => onSubmitted({...netRenter, id: resp.data.id}))
      .catch(error => {
        if (error.response.status === 400) {
          if(error.response.data.error.errors)
            throw new SubmissionError(
              error.response.data.error.errors.reduce(
                (errors, {propertyPath, message}) => {
                  return set(errors, 'netRenter.' + propertyPath, message);
                },
                {}
              )
            );
          else {
            alert(error.response.data.error);
          }
        } else if (error.response.status === 403) {
          alert('У вас недостаточно прав для выполнения данного действия');
        } else {
          alert('Произошла ошибка');
        }
      });
  };

  onContactSubmit = ({contact}) => {
    const {change} = this.props;

    return axios
      .post('/api/contacts', {contact})
      .then(resp => {
        change('netRenter.contact', resp.data.id);
        this.setState({
          createContact: null,
          contact: {
            ...contact,
            id: resp.data.id,
            assignees: resp.data.assignees,
          },
        });
      })
      .catch(error => {
        if (error.response.status === 400) {
          throw new SubmissionError(
            error.response.data.error.errors.reduce(
              (errors, {propertyPath, message}) => {
                return set(errors, propertyPath, message);
              },
              {}
            )
          );
        } else {
          alert('Произошла ошибка');
        }
      });
  };
}

const styles = () => ({
  dialog: {
    maxWidth: 960,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

NetRenterEdit = withStyles(styles)(NetRenterEdit);

const selector = formValueSelector('netRenter');
NetRenterEdit = connect(state => ({
  selfUser: state.root.selfUser,
  teams: state.root.selfUser.teams,
  city: selector(state, 'netRenter.city'),
  logos: selector(state, 'netRenter.logos'),
  contactType: selector(state, 'contact.type'),
  contactAttributes: selector(state, 'contact.attributes'),
  contactPersons: selector(state, 'contact.persons'),
  contactSubject: selector(state, 'contact.subject'),
  blacklist: selector(state, 'contact.blacklist'),
  showTeam: selector(state, 'contact.showTeam'),
}))(NetRenterEdit);

export default reduxForm({
  form: 'netRenter',
})(NetRenterEdit);
