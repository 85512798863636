import axios from 'axios';
import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {TextField} from 'redux-form-material-ui';
import * as icons from '@material-ui/icons';
import * as colors from '@material-ui/core/colors';

import Notice from './components/Notice';
import SnackbarError from './components/SnackbarError';
import SnackbarSuccess from './components/SnackbarSuccess';

export default () => Component => {
  class Auth extends Component {
    state = {
      authentication: false,
      resetPassword: false,
      load: false,
      newVersion: null,
      errorMessage: '',
      error: false,
    };
    versionError = 0;
    checkVersionTime = 600000;
    checkVersionTimer = null;

    /*lock = new Auth0Lock('1q59usogSZv5DzGyE1SG2hf4Melb0Cqk', 'arris.eu.auth0.com', {
      auth: {
        sso: false,
        responseType: 'token',
        audience: window.location.origin + '/api',
        redirectUrl: window.location.origin,
      },
      closable: false,
      language: 'ru',
      languageDictionary: {
        title: 'ARR.IS',
      },
    });*/

    componentDidMount() {
      window.addEventListener('storage', this.changeVersionCache);
      window.addEventListener('keydown', this.onKeyDown);
      this.checkSession();
      /*setInterval(() => {
        this.checkSession();
      }, 3600000);*/
    }

    onKeyDown = ({key}) => {
      if(key === 'Enter'){
        this.handleSubmit();
      }
    };

    changeVersionCache = event => {
      if(event.key === 'version' && event.oldValue !== ''){
        this.setState({
          newVersion: event.newValue,
        });
      }
    };

    checkSession() {
      const {authenticate} = this.props;

      let authToken;
      try {
        authToken = JSON.parse(localStorage.getItem('authToken'));
        this.setState({
          load: true,
        });
      } catch (e) {
        return;
      }

      if (!authToken) {
        return;
      }
      this.setState({authentication: true});

      axios
        .create()
        .get(
          '/api/security/isValidToken',
          {
            headers: {
              Authorization: `${authToken.tokenType} ${authToken.accessToken}`,
            },
          }
        )

        .then(
          () => {
            authenticate(authToken);
            this.startCheckVersion();
            window.removeEventListener('keydown', this.onKeyDown);
          },
          error => {
            if(error.response.status === 403)
              localStorage.removeItem('authToken');
          })
        .then(() => {
          this.setState({authentication: false});
        });

      /*.catch(error => {
        if (error.response.status === 403) {
          alert('Логин или пароль введён не верно');
        } else {
          alert('Произошла ошибка');
        }
      });*/

      /*const {authenticate} = this.props;

      this.setState({authentication: true});

      this.lock.checkSession({}, (err, authResult) => {
        this.setState({authentication: false});

        if (authResult) {
          authenticate(authResult);
        } else {
          this.lock.show();
        }
      });*/
    }

    logout = () => {
      this.props.logout();
      window.addEventListener('keydown', this.onKeyDown);
      if(this.checkVersionTimer){
        clearInterval(this.checkVersionTimer);
        this.checkVersionTimer = null;
      }
    };

    render() {
      const {authenticated, version, ...props} = this.props;
      const {
        authentication,
        resetPassword,
        submitting,
        load,
        newVersion,
        successMessage,
        errorMessage,
        error,
      } = this.state;

      if(!load && !authentication)
        return null;
      if (authenticated) {
        return (
          <React.Fragment>
            <Component
              {...props}
              version={version}
              authenticated={authenticated}
              logout={() => {
                this.logout();
              }}
            />
            {newVersion ?
              <Notice
                message={
                  <React.Fragment>
                    Система обновлена до версии {newVersion}.
                    <br/>
                    Чтобы получить обновления{' '}
                    <a
                      style={{color: 'rgba(0, 0, 0, 0.87)'}}
                      onClick={e => {
                        e.preventDefault();
                        localStorage.setItem('version', newVersion);
                        document.location.reload(true);
                      }} href="/#">перезагрузите страницу</a>.
                  </React.Fragment>
                }
              />
              : null}
          </React.Fragment>
        );
      }

      if (authentication) {
        return (
          <div
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <icons.Lock
              style={{color: colors.grey[400], width: 32, height: 32}}
            />
            <CircularProgress
              style={{color: colors.grey[400], position: 'absolute'}}
              size={100}
              thickness={2}
            />
          </div>
        );
      }

      return (
        <div
          style={{
            backgroundImage: 'url(/assets/bg/auth.jpg)',
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
          }}>
          <Dialog open maxWidth="xs" fullWidth>
            <DialogTitle>
              {!resetPassword ? 'Авторизация' : 'Восстановление доступа'}
            </DialogTitle>
            <Divider />
            <DialogContent>
              <TextField
                name="username"
                label="Адрес эл. почты"
                type="email"
                inputProps={{ref: input => (this.username = input)}}
                fullWidth
                margin="normal"
              />
              {!resetPassword ? (
                <TextField
                  name="password"
                  label="Пароль"
                  type="password"
                  inputProps={{ref: input => (this.password = input)}}
                  fullWidth
                  margin="normal"
                />
              ) : null}
              {!resetPassword ? (
                <div>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="a"
                    href=""
                    style={{marginTop: 8, marginBottom: -8}}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({resetPassword: true});
                    }}
                  >
                    Забыли пароль?
                  </Typography>

                  <br/>
                  <div style={{display: 'block', textAlign: 'center'}}>
                    <a
                      href="https://t.me/IS_CRENOW_BOT?start=login"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textAlign: 'center',
                        textDecoration: 'none',
                        height: 24,
                        lineHeight: '24px',
                        verticalAlign: 'middle',
                        display: 'inline-block',
                        alignItems: 'flex-start',
                        marginRight: 10,
                        marginTop: 10,
                      }}
                    >
                      Войти через&nbsp;
                      <img src="/assets/social/telegram.png" width="16" height="16" alt="Telegram" title="Telegram"/>
                      &nbsp;Telegram
                    </a>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <Typography style={{marginTop: 8}}>
                    На указанный адрес эл. почты будет отправлена инструкция для
                    восстановления доступа.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="a"
                    href=""
                    style={{marginTop: 8, marginBottom: -8, textDecoration: 'none'}}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({resetPassword: false});
                    }}>
                    <span style={{borderBottom: '1px solid rgba(0, 0, 0, 0.54)', display: 'inline-block', lineHeight: '0.9'}}>Назад</span>
                  </Typography>
                </React.Fragment>
              )}
            </DialogContent>
            <Divider />
            <DialogActions>
              {this.props.version ?
                !resetPassword ?
                  <span style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    opacity: .5,
                    fontWeight: 400,
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    lineHeight: '1.46429em',
                    fontSize: 14,
                  }}>
                Версия {this.props.version}
                  </span>
                  : <span style={{
                    position: 'absolute',
                    left: '24px',
                    opacity: .5,
                    fontWeight: 400,
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    lineHeight: '1.46429em',
                    fontSize: 14,
                  }}>
                    Версия {this.props.version}
                  </span>
                : null}
              <Button
                type="submit"
                disabled={submitting}
                onClick={this.handleSubmit}
              >
                {!resetPassword ? 'Войти' : 'Восстановить'}
              </Button>
            </DialogActions>
          </Dialog>
          <SnackbarError
            open={!!error}
            errorMessage={errorMessage}
            onClose={() => this.setState({error: false})}
          />
          <SnackbarSuccess
            open={!!successMessage}
            message={successMessage}
            onClose={() => this.setState({successMessage: null})}
          />
        </div>
      );
    }

    startCheckVersion = () => {
      if(this.checkVersionTimer)
        return;
      this.checkVersionTimer = setInterval(this.checkVersion, this.checkVersionTime);
    };

    checkVersion = () => {
      const {version} = this.props;
      let time = localStorage['timeVersion'] || 0;
      let sec = Date.now();
      if(version && time <= sec - this.checkVersionTime){
        localStorage.setItem('timeVersion', sec);
        if(this.versionError < 5)
          axios.get('/api/version').then(resp => {
            this.versionError = 0;
            if(version !== resp.data.version){
              this.setState({
                newVersion: resp.data.version,
              });
              localStorage.setItem('version', resp.data.version);
            }
          })
            .catch(() => {
              this.versionError++;
            });
      }
    };

    handleSubmit = () => {
      const {authenticate, setVersion, version} = this.props;
      const {resetPassword} = this.state;


      if (resetPassword) {
        if(this.username.value){
          this.setState({submitting: true});
          axios
            .create()
            .post('/api/security/passwordResets', {
              username: this.username.value,
            })
            .then(res => {
              this.setState({
                resetPassword: false,
                submitting: false,
                successMessage: res && res.data && res.data.message ? res.data.message : null,
              });
              this.username.value = null;
            })
            .catch(error => {
              if(error.response.status === 400)
                this.setState({errorMessage: error.response.data.error, error: true});
              else
                this.setState({errorMessage: 'Неизвестная ошибка сервера', error: true});
            })
            .then(() => {
              this.setState({
                submitting: false,
              });
            });
        }
        return;
      }
      if(this.username.value || this.password.value){
        this.setState({submitting: true});
        axios
          .create()
          .post('/api/security/tokens', {
            username: this.username.value,
            password: this.password.value,
          })
          .then(resp => {
            window.removeEventListener('keydown', this.onKeyDown);
            authenticate(resp.data);
            this.startCheckVersion();
            if(version && version !== resp.data.version)
              this.setState({
                newVersion: resp.data.version,
              });
            localStorage.removeItem('unreadNotification');
            localStorage.removeItem('totalCountNotification');
            localStorage.removeItem('timeNotification');
            localStorage.removeItem('newCountNotification');
            setVersion(resp.data.version);
          })
          .catch(error => {
            if (error.response.status === 403) {
              this.setState({errorMessage: 'Доступ в систему закрыт', error: true});
            } else {
              if(error.response.status === 400)
                this.setState({errorMessage: error.response.data.error, error: true});
              else
                this.setState({errorMessage: 'Неизвестная ошибка сервера', error: true});
            }
          })
          .then(() => {
            this.setState({submitting: false});
          });
      }
    };
  }

  return Auth;
};
